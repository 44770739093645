import { format, isToday } from "date-fns";
import React from "react";
import { Document } from "firestorter";
import { Badge, Card, Overlay, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { Booking } from "../models/DataModels";
import { observable, makeObservable } from "mobx";
import { observer } from "mobx-react";
import { BookingState } from "../state/BookingState";
import { Bookings } from "../logic/Bookings";
import { UserBadge } from "./UserBadge";
import { StringHelpers } from "../logic/StringHelper";
import { Resources } from "../logic/Resources";
import {BiAtom} from "react-icons/bi"
import {FiPlusCircle} from "react-icons/fi"
import { App } from "../App";
import { RiAliensFill } from "react-icons/ri";

export interface IProps {
    date: Date
    bookings: Document<Booking>[]
    showNames: boolean
    showFire?: boolean
    showFirstAid?: boolean
}

@observer
export class CalendarDay extends React.Component<IProps, {}>
{


    @observable
    private hovering: boolean = false;
    
    constructor(props: IProps) {
        super(props);
        makeObservable(this)
    }
 

    render() {

        let date = this.props.date;
        let bookings = this.props.bookings.sort((a,b)=>a.data.name.localeCompare(b.data.name));

        let today = false;//isToday(this.props.date)

        let userCanBook = Bookings.currentUserCanBookDate(date);
        let userIsBooked = Bookings.isCurrentUserBookedOnDate(date);
        let keyHolderisPresent = Bookings.isKeyHolderBooked(this.props.bookings)
        let fullyBooked = Bookings.isDayFullyBooked(date);
        let openHours = Bookings.getOpeningHours(this.props.date);

        let userBooking = Bookings.getCurrentUserBookingOnDate(date);


        let cellClass = "border-primary"

        if (today) {
            cellClass = "bg-info border-primary"
        }
        if (this.hovering) {
            cellClass = "bg-secondary border-secondary";
        }

        if (fullyBooked) {
            cellClass = cellClass + " day-full"
        }
        else if (!keyHolderisPresent) {
            cellClass = cellClass + " no-keyholder"
        }
 
 
        return <>

                <td 

                    onClick={() => BookingState.OpenBookingForm(this.props.date)}
                    className={cellClass}
                    style={{ padding: 0, boxShadow: "none", cursor: this.hovering && (userCanBook || userIsBooked) ? "pointer" : "default", }}
 

                    onMouseLeave={() => {this.hovering = false; BookingState.HoverDate = undefined }}
                    onMouseOver={() => { this.hovering = true; BookingState.HoverDate = this.props.date }}>


                    


                <div style={{ textAlign: "center", fontWeight: 700, height:"2.5em", position:"relative" }}>
                <Badge  bg="secondary" style={{ borderRadius:"0px 0px  0px 3px", fontSize:"13px", position: "absolute", right: 0}} > <RiAliensFill /> {bookings.length} </Badge>
                    <span className="booking-table-day-name">{format(date, 'E')} </span>
                    
                    {format(date, 'd LLL')}

                    

                    {<div style={{color: "#ccc"}}>{StringHelpers.formatHours(openHours)}</div>}  
                </div>


                <div style={{ padding: "10px", borderRadius: 3, minHeight: 10, maxWidth: 300, fontWeight:100, fontSize:20 }}>

<div>
                { !userIsBooked && userCanBook && App.Settings.showSpaceCount &&
                    <Badge bg="secondary">{StringHelpers.plural(Bookings.getSpacesOnDay(date),"space").toUpperCase()}</Badge>}

                {userIsBooked && <Badge bg="info">BOOKED
                
                {userBooking && userBooking.data.bookedResources?.map(b=> <div key={b}>
                    <small  style={{fontWeight:400}}> {Resources.getResource(b)?.data.name.toUpperCase()}</small>
                </div> )}
                
                </Badge>}

            

                {!userIsBooked && fullyBooked && <div><Badge bg="primary">FULL</Badge></div>}
                </div>

                    {this.props.showNames && bookings?.map(booking => <div key={booking.id} ><UserBadge booking={booking.data} showFire={this.props.showFire} showFirstAid={this.props.showFirstAid} /></div>)}


                </div>
     
        </td>

        </>
    }

}