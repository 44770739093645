
import { observer } from "mobx-react";
import React from "react";
import { Alert, Form } from "react-bootstrap";
import { App } from "../App";
import { Calendar } from "../components/Calendar";
import { addDays, startOfToday } from "date-fns";
import { Bookings } from "../logic/Bookings";
import { MenuScreen } from "../components/Layouts";



@observer
export class EmbeddedBookingPage extends React.Component<{}, {}>
{
 

    render() {

        const keyProps = { display: "flex", verticalAlign: "middle", height: 32, padding: 10, alignItems: "center",
        marginRight:10, width:50 };

        const keyLabelProps = {
            marginRight:15,
            verticalAlign:"middle",
            lineHeight: 2
        }

        let startDate = startOfToday();
        let endDate = addDays(startDate, Bookings.daysUserCanBookInAdvance())

    
        return <div style={{margin: 10}}>


            <style>
                {`
                    /* width */
                    ::-webkit-scrollbar {
                    width: 10px;

                    }

                    /* Track */
                    ::-webkit-scrollbar-track {
                    background: #1e1b4b;
                    border-radius: 10px;
                    }

                    /* Handle */
                    ::-webkit-scrollbar-thumb {
                    background: #9333ea;
                    border-radius: 10px;
                    }

                    /* Handle on hover */
                    ::-webkit-scrollbar-thumb:hover {
                    background: #b535ec;
                    }


                    body {
                        background: transparent
                    }

                    .table-dark {
                           --bs-table-bg: #090b38;
                      }

                      .table-striped > tbody > tr:nth-of-type(odd) {
                        --bs-table-accent-bg: #060827;
                      }

                      .badge.bg-dark {
                        background-color: transparent !important;
                    }

                    

`}
            </style>


            {!App.UserData.data.allowBooking &&
                 
                <Alert variant="danger">Your account is not yet activated for booking, please contact the studio team for more information</Alert> 
            }
           
            <div style={{ display: "flex", marginBottom: 0}}>
            <Form.Check id="chk-show-bookings" label="Show who's booked" checked={ App.UserPrefs.showBookings} 
            onChange={e=> App.UserPrefs.showBookings = e.currentTarget.checked}/>
            <div style={{width:10}}/>
            <Form.Check id="chk-show-badge" label="Show Badges" checked={ App.UserPrefs.showBadges} 
            onChange={e=> App.UserPrefs.showBadges = e.currentTarget.checked}/>
            </div>

<div style={{width:"100%", height:"5px", background:"#6f42c1", borderRadius:"5px 5px 0px 0px"}}></div>
            <Calendar showNames={App.UserPrefs.showBookings} startDate={startDate} endDate={endDate}
                bookings={App.Bookings} showFire={App.UserPrefs.showBadges} showFirstAid={App.UserPrefs.showBadges} />


<div style={{ display: "flex", marginBottom: 10 }}>
                
                <div className="no-keyholder" style={keyProps}></div><strong style={keyLabelProps}>NO KEYHOLDER</strong>

            </div>


        </div>
    }

}