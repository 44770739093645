
import { observer } from "mobx-react";
import React from "react";
import { Badge, Button, Card, Col, Container, Form, Nav, Row, Table } from "react-bootstrap";
import { App } from "../App";
import { Booking } from "../models/DataModels";
import { Calendar } from "../components/Calendar";
import { startOfToday } from "date-fns";
import firebase from "firebase";
import { makeObservable, observable } from "mobx";
import { MenuScreen } from "../components/Layouts";



@observer
export class SeatingPlanPage extends React.Component<{}, {}>
{
    @observable
    private url?: string = undefined;

    @observable
    private page?:number = 1;

    constructor(props:{}) {
      super(props)
      makeObservable(this) 
    }

    
    async componentDidMount()
    {
      await this.loadUrl();
    }
    async loadUrl()
    {
      var storage = firebase.storage();
      var pathReference = storage.ref("SeatingPlan.pdf");
      this.url = undefined;
      this.url = await pathReference.getDownloadURL();
    }

    //https://docs.google.com/presentation/d/e/2PACX-1vRf92tTQ-PkScLW_14jt8iyvGY2jLw4vNhz0x8lzZBTJxmLICLu9kJpLqJNEqUGV2-5MHY7xxzsBFYV/embed?start=false&loop=false&delayms=3000&slide=id.p
    //             <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vRf92tTQ-PkScLW_14jt8iyvGY2jLw4vNhz0x8lzZBTJxmLICLu9kJpLqJNEqUGV2-5MHY7xxzsBFYV/embed?start=false&loop=false&delayms=3000" 
// frameBorder="0" width="850" height="1730" allowFullScreen={true} ></iframe>    

    private slides = 
    [
        "Ground Floor Dev Room",
        "First Floor Jupiter Room",
        "Other Rooms"
    ]
    
    render() {

        return <>
        
        {/*<Nav style={{position: "absolute", right:100, top: 100}} variant="pills" defaultActiveKey={this.slides[0]}  >
                    {this.slides.map( (slide,index) => <Nav.Item> 
                        <Nav.Link eventKey={slide} onClick={()=>this.page = index+1}>{slide}</Nav.Link>
                    </Nav.Item>)}
                    </Nav>  */}


        <MenuScreen title="Seating Plan">

            <div style={{ margin: "0 auto" }}> 
      
         

                    <embed
    key={this.page}
                        src={`${this.url}#view=FitH&pagemode=thumbs&page=${this.page}`}
                        style={{
                       
                            width: "100%",
                            height: "calc(100vh - 220px)",
                        }}
                    ></embed>

            </div>

        </MenuScreen></>;
    }

}