import { App } from "../App";
import { AggregateCollection, Document, ICollection } from "firestorter";
import { Booking, IWorkingHours, Resource } from "../models/DataModels";
import { Collection } from "../Database";
import { differenceInHours, isAfter, isBefore, isSameDay, startOfDay } from "date-fns";
import { computed, toJS } from "mobx";
import { MdYoutubeSearchedFor } from "react-icons/md";
import { DateHelper } from "./DateHelper";
import { Bookings } from "./Bookings";

interface ResourceUsage
{
    resource: Resource,
    bookings: Booking[]
}

export class Resources
{

    public static userHasAccessToResource(resource: Resource)
    {
        let userRoleIds = App.UserData.data.customRoles;

        let resourceRoleIds = resource.requiredRoleIds;

        if (!resourceRoleIds || resourceRoleIds.length == 0)
        {
            return true;
        }

        if (userRoleIds)
        {

            for ( let userRoleId of userRoleIds )
            {
                for ( let resourceRoleId of resourceRoleIds)
                {
                    if (userRoleId === resourceRoleId)
                    {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    public static getResource(resourceId:string): Document<Resource>|undefined
    {
        return App.Resources.find( r=> r.id == resourceId );
    }

    public static getResourcesAvailableToUser():  Document<Resource>[] 
    {
        return App.Resources.filter( resource =>  this.userHasAccessToResource(resource.data) && resource.data.enabled );
    }

    public static groupResources(resources:Document<Resource>[] ): Map<string,Document<Resource>[]>
    {
        let result = new Map<string,Document<Resource>[]>();
        for (let resource of resources )
        {
            if ( !result.has(resource.data.group))
            {
                result.set(resource.data.group,[]);
            }

            result.get(resource.data.group)?.push(resource);
        }
      
        return result;
    }

    public static getFullyBookedResources(date:Date): string[]
    {
        let result:string[] = [];
        this.getResourceAvailabilityOnDay(date).forEach( (capacity, id) => {
            if (capacity <= 0 )
            {
                result.push(id);
            }
        })
       return result;

    }

    public static getResourceAvailabilityOnDay(date:Date): Map<string,number>
    {
        let result = new Map<string,number>();

        let bookings = Bookings.getBookingsOnDate(date);

        for (let resource of App.Resources)
        {
            result.set(resource.id as string, resource.data.quantity);
        }

        for (let booking of bookings)
        {
            if ( booking.data.bookedResources)
            {
                for ( let bookedResourceId of booking.data.bookedResources)
                {
                    result.set(bookedResourceId, (result.get(bookedResourceId) || 0) -1 );
                }
            }
        }

        return result;


    }

    

    public static getResourceUsageOnDay(date:Date): ResourceUsage[]
    {
        let result = new Map<string,ResourceUsage>();

        let bookings = Bookings.getBookingsOnDate(date);

        for (let resource of App.Resources)
        {
            result.set(resource.id as string, {
                resource: resource.data,
                bookings: []
            })
        }

        for (let booking of bookings)
        {
            if ( booking.data.bookedResources)
            {
                for ( let bookedResourceId of booking.data.bookedResources)
                {
                    let resourceUsage = result.get(bookedResourceId);
                    resourceUsage?.bookings.push(booking.data);
                }
            }
        }

        return Array.from(result.values());
    }
 


}