import React from "react";
import { Badge, Button, Card, Col, Container, Form, Nav, Row, Tab, Table, Tabs } from "react-bootstrap";
import { App } from "../App";
import { observer } from "mobx-react";
import {SortableContainer, SortableElement,SortableHandle} from 'react-sortable-hoc';
import { ImportantInfo } from "../models/DataModels";
import {Document} from 'firestorter'
import arrayMove from 'array-move';
import {MdDragHandle} from "react-icons/md"
import { UploadComponent } from "./UploadComponent";
import { AdminRoles } from "./AdminRoles";



const DragHandle = SortableHandle(() => <MdDragHandle size="24" style={{cursor: "move"}}/>);

const DraggableImportantInfoCard = SortableElement((props: { value: Document<ImportantInfo> }) => { 
return <Card bg={props.value.data.enabled ? "primary" : "dark"}>
  <Card.Body>
    <DragHandle />
    <Button style={{ float: "right" }} onClick={e => props.value.delete()}>Delete</Button>
    <Form.Check inline label="Enabled" checked={props.value.data.enabled}
      onChange={e => props.value.data.enabled = e.currentTarget.checked}
    />
    <Form.Control value={props.value.data.title} onChange={e => props.value.data.title = e.currentTarget.value} />
    <Form.Control onChange={e => props.value.data.details = e.currentTarget.value} as="textarea" value={props.value.data.details} />
  </Card.Body>
</Card>
});

const DraggableImportantInfoList = SortableContainer(({items}:{items:Document<ImportantInfo>[]}) => {
  return (
    <ul>
      {items.map((value, index) => (
        <DraggableImportantInfoCard key={`item-${value.id}`} index={index} value={value} />
      ))}
    </ul>
  );
});


@observer
export class AdminSettings extends React.Component<{},{}>
{
    render()
    {

      // bind data
      this.importantInfos.map((value, index) => {
        let a:any = value.data.title
        a = value.data.details
        a = value.data.enabled
      });


      return <>
      <Table striped bordered hover variant="dark">
        <thead>
          <tr>
            <th></th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Maximum days in advance that can be booked</td>
            <td>

              <Form.Control value={App.Settings.maxDaysInAdvance} type="number" placeholder="Enter number of days"
                required onChange={e => App.Settings.update({ maxDaysInAdvance: parseInt(e.currentTarget.value) })} />
            </td>
          </tr>
          <tr>
            <td>Keyholder must book before anyone else</td>
            <td>

              <Form.Check inline type="checkbox" checked={App.Settings.keyHolderMustBookFirst}
                onChange={(e) => App.Settings.update({ keyHolderMustBookFirst: e.currentTarget.checked })} />


            </td>
          </tr>

          <tr>
            <td>Enable resource booking</td>
            <td>

              <Form.Check inline type="checkbox" checked={App.Settings.enableResourceBooking}
                onChange={(e) => App.Settings.update({ enableResourceBooking: e.currentTarget.checked })} />

 
            </td>
          </tr>

          <tr>
            <td>Show number of available spaces in the office?</td>
            <td>

              <Form.Check inline type="checkbox" checked={App.Settings.showSpaceCount}
                onChange={(e) => App.Settings.update({ showSpaceCount: e.currentTarget.checked })} />


            </td>
          </tr>

          <tr>
            <td>Seating Plan</td>
            <td><UploadComponent path="SeatingPlan.pdf"/></td>
          </tr>
          <tr>
            <td>Guide</td>
            <td><UploadComponent path="Guide.pdf"/></td>
          </tr>
          <tr>
            <td>FAQ</td>
            <td><UploadComponent path="FAQ.pdf"/></td>
          </tr>      
          </tbody>
          </Table>

          <h2>Customise Important Info</h2>

          <Table striped bordered hover variant="dark">
            <tbody>       

          <tr>
            <td colSpan={2}>
              

              <Form>
                <DraggableImportantInfoList items={this.importantInfos} useDragHandle onSortEnd={e => this.onSortEnd(e)} />
                
                <div style={{ marginTop: 10 }}>
                  <Button onClick={e => this.addNew()}>Add New</Button>{" "}
                  <Button onClick={e => this.saveImportantInfo()}>Save</Button>
                </div>
              </Form>

            </td>
          </tr>


        </tbody>
      </Table>

      <h2>Custom Roles</h2>
      <AdminRoles/>

      </>
    
    }

    get importantInfos()
    {
      return App.Database.PublicSettings.ImportantInfo.docs.slice().sort((a,b)=> a.data.order - b.data.order );
    }

    private onSortEnd(a:{newIndex:number,oldIndex:number})
    {
      let x = arrayMove(this.importantInfos.slice(),a.oldIndex, a.newIndex)
      for ( let i = 0; i < x.length; i++ )
      {
        x[i].data.order = i;
      }
    }

    async addNew()
    {
      let doc = await App.Database.PublicSettings.ImportantInfo.add({
        enabled: false, title: "new", details: "", order: this.importantInfos.length+1
      }) 

    }

    saveImportantInfo()
    {
      let order = 0;
      for (let doc of this.importantInfos)
      {

        doc.update(
          {
            enabled: doc.data.enabled,
            title: doc.data.title,
            details: doc.data.details,
            order: order
          }
        )

        order++
      }
    }
}