import React from "react";
import { Alert, Badge, Button, Card, Col, Container, Form, Modal, Nav, Row, Spinner, Tab, Table, Tabs } from "react-bootstrap";
import { GiHealthCapsule, GiSkeletonKey } from "react-icons/gi";
import { App } from "../App";
import { StringHelpers } from "../logic/StringHelper";
import { observer } from "mobx-react";
import { Bookings } from "../logic/Bookings";
import { AggregateCollection, Document, ICollection } from "firestorter";
import { Booking } from "../models/DataModels";
import { makeObservable, observable } from "mobx";
import { format, startOfToday } from "date-fns";
import {IoAddCircle} from "react-icons/io5"
import DatePicker from "react-datepicker";
import firebase from "firebase";
import { ResourceBooking } from "./ResourceBooking";
import { Resources } from "../logic/Resources";

@observer
export class AdminManageBookings extends React.Component<{},{}>
{
  constructor(props:{})
  {
      super(props) 
      this.selectedResources =  new Set<string>();
      makeObservable(this); 
  }
  
  
    @observable
    private manageBooking?: Document<Booking> = undefined

    @observable
    private addVisitor?: boolean = false

    @observable
    private addTeamMember?: boolean = false

    @observable
    private busy?: boolean = false

    @observable
    addDate: Date = startOfToday();   
    
    @observable
    selectedTeamMember: string = ""

    @observable
    visitorName: string = ""

    @observable
    note: string = ""

    @observable
    selectedResources:Set<string>;


  async addBooking() 
  {
      this.busy = true;

      let displayName = ""

      if (this.addVisitor)
      {
        displayName =  this.visitorName;
      }
      else if ( this.addTeamMember )
      {
        let userDoc = App.Database.getUserDoc(this.selectedTeamMember);
        displayName = StringHelpers.displayName(userDoc)
      }


      let booking: Booking = {
        date: firebase.firestore.Timestamp.fromDate(this.addDate),
        name: displayName,
        email: this.addVisitor ? `${this.visitorName}@vistors` : this.selectedTeamMember,
        created: firebase.firestore.Timestamp.now(),
        visitor: this.addVisitor,
        bookedResources: Array.from( this.selectedResources ),
        note: this.note
      }

      //if (bookingInfo.workingHours != null)
      //{
      // booking.workingHours = {...bookingInfo.workingHours}
      //}

      await App.Database.Bookings.add(booking)

      this.busy = false;

      this.addTeamMember = false;
      this.addVisitor = false;
      


  }

  toggleResource(resourceId: string)
  {
    if (this.selectedResources.has(resourceId))
    {
      this.selectedResources.delete(resourceId); 
    }
    else
    {
      this.selectedResources.add(resourceId);
    }
  }


    render()
    {
      let bookings = Bookings.bucketBookingsByDay( App.Bookings.filter(b=>b.data.date.toDate() >= startOfToday()) );

      let data: JSX.Element[] = [];

      bookings.forEach( (bookings: Document<Booking>[], key: string) => 
      {
        let date = new Date(key)
        let capacity = Bookings.getCapacityOnDay(date)
        let full = bookings.length == capacity;
        let overFull = bookings.length > capacity;



        data.push(<tr>
            <td style={{width:120}}>{format(date,"E dd MMM")}</td>
            <td style={{boxShadow:"none"}} className={overFull ? "bg-danger" : (full ? "day-full" : "") }>{bookings.length}/{capacity}</td>
            <td>
                {bookings?.sort((a,b)=>a.data.name.localeCompare(b.data.name) ).map(b=><>
                  <Button style={{marginBottom:4}} onClick={()=> this.manageBooking = b}>{b.data.name}</Button>{' '}
                </>)} 
                <Button className={(full || overFull) ? "day-full" : ""} style={{marginBottom:4}} onClick={()=>{this.addDate = date; this.addTeamMember = true}} variant="info"><IoAddCircle size="24"/></Button>
            </td>
        </tr>)
      })


      let addCapacity = Bookings.getCapacityOnDay(this.addDate)
      let addSpaces = Bookings.getSpacesOnDay(this.addDate)
      let addbookingCount = Bookings.getNumberOfBookingsOnDate(this.addDate)
      let addUserAlreadyBooked = ( this.addTeamMember && Bookings.isUserBookedOnDate(this.addDate, this.selectedTeamMember))



      return <div> 
          <div style={{float:"right"}}>
           <Button size="lg" onClick={()=>{this.addTeamMember = true}} variant="info">Add Team Member</Button>&nbsp;
           <Button size="lg" onClick={()=>{this.addVisitor = true}}  variant="info">Add Visitor</Button>
           </div>

           <h1>Bookings</h1>

          <Table striped bordered hover variant="dark">
            <tbody>{data}</tbody>
        </Table>

        {/* BOOKING INFORMATION AND CANCELLATION FORM */}
        {this.manageBooking && <Modal         size="lg"
        centered contentClassName="bg-dark" show={true} onHide={()=>{this.manageBooking = undefined}}>
        <Modal.Header closeButton>
          <Modal.Title>{this.manageBooking.data.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <p>{format(this.manageBooking.data.date.toDate(),"E dd MMM yyy")}</p>

          <p>
          <strong>Resources Booked:</strong>
          {this.manageBooking.data.bookedResources?.map( resource => 
           <li> {Resources.getResource(resource)?.data.name} </li>
          )}
          </p>
         
        
        </Modal.Body>
        <Modal.Footer>
        <Button onClick={()=>{this.manageBooking = undefined}} variant="dark" >Cancel</Button>
          <Button variant="danger" onClick={()=>{
            this.manageBooking?.delete();
            this.manageBooking = undefined

          }}>
            Delete This Booking
          </Button>
        </Modal.Footer>
      </Modal>}

    {/* ADD NEW BOOKING FORM */}
      {(this.addTeamMember || this.addVisitor) && <Modal size="lg"
        centered contentClassName="bg-dark" show={true} onHide={()=>{this.addVisitor = false; this.addTeamMember = false}}>
      <Modal.Header closeButton>
          <Modal.Title>Add Booking</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {this.busy &&  <Spinner animation="border" />}

          {this.addTeamMember && !this.busy && <Form.Group>
          <Form.Label><strong>TEAM MEMBER</strong></Form.Label>
          <Form.Select value={this.selectedTeamMember} onChange={e=> this.selectedTeamMember = e.currentTarget.value }>
            <option></option>
            {App.Database.Users.docs.map( user => <option value={user.id}>{StringHelpers.displayName(user)}</option> )}
          </Form.Select>   
          
          </Form.Group>}

          <Form.Label><strong>PUBLIC NOTE</strong></Form.Label>
          <Form.Control value={this.note} onChange={e=>this.note = e.currentTarget.value} type="input" placeholder="Displayed next to booking" />

          {this.addVisitor  && !this.busy && <Form.Group>
          <Form.Label><strong>VISITOR NAME</strong></Form.Label>
          <Form.Control value={this.visitorName} onChange={e=> this.visitorName = e.currentTarget.value } />        
          </Form.Group>}

        <br/>

          {!this.busy && 
          <Form.Group>
          <Form.Label><strong>DATE</strong></Form.Label>
          <div>
          <DatePicker  locale="en-GB" dateFormat="dd MMM yyyy" className="form-control" 
            selected={this.addDate} onChange={(date) => this.addDate = date as Date} /> </div>

<Alert variant={addSpaces <= 0 ? "danger" : ""}>
{addSpaces >= 0 && StringHelpers.plural(addSpaces,"space")} ({addbookingCount}/{addCapacity} booked)
</Alert>

{addUserAlreadyBooked && <Alert variant="danger">TEAM MEMBER IS ALREADY BOOKED ON THIS DATE!</Alert>}

            </Form.Group>}


            <ResourceBooking date={this.addDate} 
              onResourceSelect={id => this.toggleResource(id)} 
              selectedResources={this.selectedResources} />

            


        </Modal.Body>
        <Modal.Footer>
        <Button disabled={this.busy} onClick={()=>{this.addVisitor = false; this.addTeamMember = false}} variant="dark" >Cancel</Button>
          <Button  disabled={this.busy||addUserAlreadyBooked} variant="info" onClick={()=>{this.addBooking()}}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>}


      </div>
    
    }
}