import { format, isSameDay } from "date-fns";
import { observer } from "mobx-react";
import React from "react";
import { Alert, Badge, Button, Col, Form, Modal, OverlayTrigger, Row, Spinner, ToggleButton, Tooltip } from "react-bootstrap";
import {BookingState, BookingFormState} from "../state/BookingState";

import { ICollection, Document } from "firestorter";
import { Booking, IWorkingHours } from "../models/DataModels";
import { Bookings } from "../logic/Bookings";
import { App } from "../App";
import { TimeSelect } from "./TimeSelect";
import { rawListeners } from "process";
import { Resources } from "../logic/Resources";
import { makeObservable, observable } from "mobx";
import { domainToASCII } from "url";
import {GoTriangleRight} from "react-icons/go"

export interface IProps
{
    date:Date;
    selectedResources:Set<string>;
    alreadyReservedResources?:Set<string>
    onResourceSelect:(resourceId:string)=>void
}

@observer
export class ResourceBooking extends React.Component<IProps,{}>
{

    componentDidUpdate()
    {
        let resourceAvailability = Resources.getResourceAvailabilityOnDay(this.props.date);

        // Ensure that if a resource is booked elsewhere, then it can no longer be booked here.
        this.props.selectedResources.forEach( x =>
        {
            if (resourceAvailability.get(x) == 0 && !this.props.alreadyReservedResources?.has(x))
            {
                this.props.onResourceSelect(x);
            }
        })
    }

    render()
    {

        let resourceGroups = Resources.groupResources( Resources.getResourcesAvailableToUser());

        let resourceAvailability = Resources.getResourceAvailabilityOnDay(this.props.date);

        let resourceGroupNames = Array.from(resourceGroups.keys());

        return resourceGroupNames.map(groupName =>{
            let resourceElements = resourceGroups.get(groupName)?.map( resource => 
                {

                    let availability = resourceAvailability.get(resource.id as string) || 0;
                    let userCanToggle = (availability > 0) 
                        || (this.props.alreadyReservedResources && this.props.alreadyReservedResources.has(resource.id as string))
                    let selected = this.props.selectedResources.has(resource.id as string);



                 
                return <OverlayTrigger
                    placement="bottom"
                overlay={
                    <Tooltip id={`tooltip-${resource.id}`}>
                    {resource.data.description}
                    </Tooltip>
                }
                >                
                <ToggleButton
                key={resource.id}
                type="checkbox"
                variant={selected ? "secondary" : "primary"}
                value="0" 
                onClick={() => this.props.onResourceSelect(resource.id as string)}
                disabled={!userCanToggle}
                style={{marginRight:3, marginBottom:3}}
              >
                  {resource.data.name}   
                {availability > 1 && <small>&nbsp;<Badge bg="dark" >{availability} available</Badge></small>} 
        
        
              </ToggleButton>
              </OverlayTrigger> }   
            )

            return <><div><Form.Text> {groupName || "General"} </Form.Text></div>{resourceElements}</>

        })
        
    }

}

    