import { makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";

import { App } from "../App";
import { DefaultUserPrefs, IWorkingHours } from "../models/DataModels";
import { Alert, Badge, Button, ButtonGroup, Col, Form, Modal, Row, Spinner, ToggleButton } from "react-bootstrap";


function Option(props: { value:string, label:string, selectedValue:string, onSelect: (value:string)=>void  }) {
    return <ToggleButton value={props.value} onClick={e=> {props.onSelect(props.value)   } } 
 variant={ props.selectedValue === props.value ? "secondary" : "primary"}>{props.label}</ToggleButton>
  }


@observer
export class TimeNote extends React.Component<{value: string, onChange: (value:string)=>void },{}>
{
    render()
    {
       return  <ButtonGroup aria-label="Basic example">
                <Option selectedValue={this.props.value} label="All Day" value="" onSelect={ v => this.props.onChange(v)} />
                <Option selectedValue={this.props.value} label="AM" value="am" onSelect={ v => this.props.onChange(v)} />
                <Option selectedValue={this.props.value} label="PM" value="pm" onSelect={ v => this.props.onChange(v)} />
                <Option selectedValue={this.props.value} label="Just Visting" value="visiting" onSelect={ v => this.props.onChange(v)} />

            </ButtonGroup>
    }
}


