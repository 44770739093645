
import { observer } from "mobx-react";
import React from "react";
import { Badge, Button, Card, Col, Container, Form, Nav, Row, Tab, Table, Tabs } from "react-bootstrap";
import { App } from "../App";
import { Booking, ICapacityRule, IPublicSettings } from "../models/DataModels";
import { Calendar } from "../components/Calendar";
import { startOfToday } from "date-fns";
import DatePicker, { registerLocale } from "react-datepicker";
import { makeObservable, observable } from "mobx";
import "react-datepicker/dist/react-datepicker.css";
import { MdAddCircle, MdRemoveCircle } from "react-icons/md";
import { GiAlienFire, GiSkeletonKey, GiHealthCapsule } from "react-icons/gi"
import { PublicSettings } from "../Database";
import { StringHelpers } from "../logic/StringHelper";
import { useRouteMatch, Link as RouterLink, useHistory, Switch, Route } from "react-router-dom";
import { AdminCapacity } from "../components/AdminCapacity";
import { AdminTeam } from "../components/AdminTeam";
import { AdminSettings } from "../components/AdminSettings";
import { DualScreen } from "../components/Layouts";
import {AiOutlineClose} from "react-icons/ai"
import { AdminManageBookings } from "../components/AdminManageBookings";
import { AdminHistory } from "../components/AdminHistory";
import { AdminResources } from "../components/AdminResources";

function AdminNavLink(props: { path: string, title: JSX.Element|string }) {
    const navigation = useHistory();

    let active: boolean = useRouteMatch({ path: props.path, exact: true }) as any;

    return <Nav.Link active={active}
        onClick={() => navigation.push(props.path)} style={{ fontSize: "1.2em" }} >{props.title}
    </Nav.Link>



}

@observer
export class AdminPage extends React.Component<{}, {}>
{

    render() {

        return <>
            <DualScreen title="Admin" sidebar={<Nav variant="pills" className="flex-column" >
                <AdminNavLink path="/admin/capacity" title="Capacity" />
                <AdminNavLink path="/admin/settings" title="Settings" />
                <AdminNavLink path="/admin/team" title="Team" />
                <AdminNavLink path="/admin/manage" title="Manage Bookings" />
                <AdminNavLink path="/admin/history" title="History" />
                <AdminNavLink path="/admin/resources" title="Resources" />
                <AdminNavLink path="/admin/export" title="Export Data" />
                <AdminNavLink path="/book" title={<div> <AiOutlineClose/> Close</div>} />
            </Nav>}>
                <Switch>
                    <Route path="/admin/capacity">
                        <AdminCapacity />
                    </Route>
                    <Route path="/admin/team">
                        <AdminTeam />
                    </Route>
                    <Route path="/admin/settings">
                        <AdminSettings />
                    </Route>
                    <Route path="/admin/manage">
                        <AdminManageBookings />
                    </Route>    
                    <Route path="/admin/history">
                        <AdminHistory />
                    </Route>
                    <Route path="/admin/resources">
                        <AdminResources />
                    </Route>                                                              
                </Switch>
            </DualScreen></>


    }

}