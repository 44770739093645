import firebase from "firebase";


export interface Booking 
{
    name:string
    date: firebase.firestore.Timestamp 
    email:string
    created: firebase.firestore.Timestamp
    workingHours?: IWorkingHours
    visitor?: boolean
    bookedResources?: string[]
    note?:string
}

export interface User
{
    isFireWarden: boolean,
    isFirstAider: boolean,
    canUnlock: boolean
    canLock: boolean
    allowBooking: boolean
    canBookAdditionalDaysAhead: number
    displayName?:string|null

    customRoles?: string[]
}

export interface ICapacityRule
{
    capacity: number,
    date: firebase.firestore.Timestamp
}

export interface ICustomRole
{
    name:string
}

export interface ImportantInfo
{
    order:number
    enabled: boolean,
    title:string,
    details: string
}

export interface Resource
{
    name:string
    enabled: boolean,
    description:string,
    quantity: number,
    requiredRoleIds: string[],
    group: string,
    showOnDashboard: boolean
}

export interface IPublicSettings
{
    maxDaysInAdvance: number,
    keyHolderMustBookFirst: boolean
    showSpaceCount: boolean
    enableResourceBooking: boolean
}

export interface IWorkingHours
{
    startHour: number
    startMinute: number
    endHour: number
    endMinute: number
}

export interface IUserPrefs
{
    showBookings: boolean,
    showBadges?: boolean,
    workingHours: IWorkingHours|null
    chosenResources?: string[]
}

export const DefaultUserPrefs: IUserPrefs = {
    showBookings: false,
    workingHours: null
    
}

