import FileUploader from "react-firebase-file-uploader";
import firebase from "firebase"
import React from "react";
import { makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { Badge } from "react-bootstrap";

@observer
export class UploadComponent extends React.Component<{path:string}>
{

    constructor(props:{path:string}) {
        super(props)
        makeObservable(this) 
  
      }

      @observable
      progress?:number = undefined;

      @observable
      complete?:boolean = false;
  

    render()
    {
        return <div><FileUploader
        accept="application/pdf"
        filename={this.props.path}
        storageRef={firebase.storage().ref("/")}
        onProgress={(progress:number) => this.progress = progress}
        onUploadSuccess={()=>this.complete = true}
        onUploadStart={()=>{this.complete = false;this.progress=0}}
      />

        <div>
      {this.progress && !this.complete && <span>{this.progress}% </span>}

      {this.complete && <Badge bg="success">COMPLETE</Badge>}
      </div>
      </div>
    }


}
