
import { observer } from "mobx-react";
import React from "react";
import { Badge, Button, Card, Col, Container, Form, Nav, Row, Tab, Table, Tabs } from "react-bootstrap";
import { App } from "../App";
import { Booking, ICapacityRule, IPublicSettings } from "../models/DataModels";
import { Calendar } from "../components/Calendar";
import { startOfToday } from "date-fns";
import DatePicker, { registerLocale } from "react-datepicker";
import { makeObservable, observable } from "mobx";
import "react-datepicker/dist/react-datepicker.css";
import { MdAddCircle, MdRemoveCircle } from "react-icons/md";
import { GiAlienFire, GiSkeletonKey, GiHealthCapsule } from "react-icons/gi"
import { PublicSettings } from "../Database";
import { StringHelpers } from "../logic/StringHelper";
import { useRouteMatch, Link as RouterLink, useHistory, Switch, Route } from "react-router-dom";
import { MenuScreen } from "../components/Layouts";
import { ResourceTable } from "../components/AdminResourceTable";


@observer
export class ResourcesPage extends React.Component<{}, {}>
{

    render() {

        return <MenuScreen title="Resources">
            <div >
            <ResourceTable daysToShowAhead={14}/>
            </div>
        </MenuScreen>

 
    }

}