import { format, isSameDay } from "date-fns";
import { observer } from "mobx-react";
import React from "react";
import { Alert, Badge, Button, Modal, Spinner } from "react-bootstrap";
import {BookingState,BookingFormState} from "../state/BookingState";

import { ICollection, Document } from "firestorter";
import { Booking, IWorkingHours } from "../models/DataModels";
import { Bookings } from "../logic/Bookings";
import { App } from "../App";
import { TimeSelect } from "./TimeSelect";
import {  makeObservable, observable } from "mobx";
import { ResourceBooking } from "./ResourceBooking";
import { TimeNote } from "./TimeNote";

export interface IProps
{
    date:Date;
}

@observer
export class ManageBookingForm extends React.Component<IProps,{}>
{

  @observable
  workingHours: IWorkingHours = {
    startHour: NaN,
    endHour: NaN,
    endMinute: NaN,
    startMinute: NaN
  }

  @observable 
  bookingNote: string = "";

  
  @observable
  selectedResources:Set<string>;

  booking?: Document<Booking>;

  constructor(props:IProps) {
    super(props)

    this.booking = Bookings.getCurrentUserBookingOnDate(props.date);
    if (this.booking?.data.workingHours)
    {
      this.workingHours = {...this.booking.data.workingHours}
    }

    this.selectedResources = new Set<string>( this.booking?.data.bookedResources );

    this.bookingNote = this.booking?.data.note || "" 

    makeObservable(this)
  }

  confirm()
  {

    

    if ( this.booking)
    {
      this.booking.update({workingHours: this.workingHours, bookedResources: Array.from(this.selectedResources), note: this.bookingNote  })
      App.UserPrefs.update({workingHours: this.workingHours })
    }


  }

    render()
    {
      let booking = Bookings.getCurrentUserBookingOnDate(this.props.date);
     
      return <Modal
        show={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName="bg-dark"
        onHide={()=>BookingState.CloseForm()}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Modify Booking
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <div>
  <Button style={{marginRight:10, float:"right"}} onClick={()=>BookingState.CancelBooking({date:this.props.date})} variant="danger" 
              disabled={BookingState.State === BookingFormState.CancelInProgress}
            >
            
                {BookingState.State == BookingFormState.CancelInProgress && 
                  <Spinner as="span" animation="border"  size="sm" role="status" />}
                {BookingState.State === BookingFormState.CancelInProgress ? "Cancelling..." : "Cancel this booking" }
            </Button>
            </div> 

          <h2>{format(this.props.date,"E dd MMM yyy")}</h2>

           <TimeNote value={this.bookingNote} onChange={v=>this.bookingNote = v}  /> 

          { App.UserData.isKeyHolder && <div>
          <Alert style={{marginTop:5}} variant="primary">
          <p>Keyholder entry/exit time</p>

          <TimeSelect value={this.workingHours} />  
          </Alert>
          </div>
          }


          {App.Settings.enableResourceBooking && <>
            <h5 style={{marginTop:15}}>Resources</h5>
                  <ResourceBooking date={this.props.date} 
                      onResourceSelect={id => this.toggleResource(id)} 
                      selectedResources={this.selectedResources} 
                      alreadyReservedResources={ new Set<string>( booking?.data.bookedResources )}
                      />
          </>}



          </Modal.Body>
          <Modal.Footer>
         


          {/*
          <Button onClick={()=>BookingState.CloseForm()} variant="dark" disabled={BookingState.State === BookingFormState.CancelInProgress} >Close</Button>
          */}



          { 
            <Button onClick={()=>{BookingState.CloseForm(); this.confirm();}} variant="info" disabled={BookingState.State === BookingFormState.CancelInProgress} >Save</Button>
          }
 
        </Modal.Footer>
      </Modal>
    }

    toggleResource(resourceId: string)
    {
      if (this.selectedResources.has(resourceId))
      {
        this.selectedResources.delete(resourceId); 
      }
      else
      {
        this.selectedResources.add(resourceId);
      }
    }
}