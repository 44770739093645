import firebase from "firebase";
import { observer } from "mobx-react";
import { useEffect, FunctionComponent } from "react";
import { Button, Container, Dropdown } from "react-bootstrap";
import { StyledFirebaseAuth } from "react-firebaseui";
import AppComponent from "./AppComponent";
import { App } from "./App";
import { firebaseConfig } from "./firebase-config";



const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/signedIn',

    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false,
    },
  };




const AuthWrapper:FunctionComponent<{}> = observer( (props)=>
{
    return !App.UserSignedIn ? <SignInScreen/> : <>{props.children}</>
});

function SignInScreen() {

    return (
      <Container style={{margin:100}}>
 
       
        <h1>Login</h1>
        <p>
            Please sign-in to this system before use
        </p>
        <div  style={{width:250}}>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />

        </div>
        

       
      </Container>
    );
  }
  
  export  {SignInScreen,AuthWrapper}