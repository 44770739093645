
import { observer } from "mobx-react";
import React from "react";
import { Badge, Button, Card, Col, Container, Form, Row, Table } from "react-bootstrap";
import { App } from "../App";
import { DateHelper } from "../logic/DateHelper";
import { Booking } from "../models/DataModels";

import {isBefore,addDays, format,isToday,startOfToday, addWeeks} from 'date-fns'
import { Document } from "firestorter";
import { Bookings } from "../logic/Bookings";
import { CalendarDay } from "./CalendarDay";


export interface IProps
{
    bookings: Document<Booking>[]
    startDate: Date
    endDate: Date
    showNames: boolean
    showFire?: boolean
    showFirstAid?: boolean    
}


@observer
export class Calendar extends React.Component<IProps,{}>
{

    private firstMondayToShow:Date = new Date(Date.now())


    private get endDate()
    {
        return this.props.endDate;
    }


    render()
    {
         this.firstMondayToShow = DateHelper.MondayBefore(this.props.startDate);
                    
            return <table style={{tableLayout: "fixed",textAlign:"center"}} className="booking-table table table-dark table-bordered  border-danger table-striped">
            <thead>
                <tr>
                <th className="bg-primary">MON</th>
                <th className="bg-primary">TUE</th>
                <th className="bg-primary">WED</th>
                <th className="bg-primary">THU</th>
                <th className="bg-primary border-primary" style={{borderRightWidth:1}}>FRI</th>
                </tr>
            </thead>
            <tbody>
                {this.renderWeeks()}
            </tbody>
            </table>

    }

    renderWeeks(): JSX.Element[]
    {
        let weeks:JSX.Element[] = [];

        let currentDay = this.firstMondayToShow;

        while (currentDay < this.endDate)
        {
            weeks.push(
                this.renderWeek(currentDay)
            )

            currentDay = addDays(currentDay,7)
        }

        return weeks;
    }

    renderWeek(monday:Date): JSX.Element
    {
        let currentDay = monday;

        let dayElements:JSX.Element[] = [];

        for(let i = 0; i < 5; i++)
        {
            dayElements.push(
                this.renderDay(currentDay)
            )
            currentDay = addDays(currentDay,1)
            if (currentDay >= this.endDate)
            {
                break;
            }
        }

        return <tr key={"week" + monday.toDateString()}>{dayElements}</tr>
    }

    renderDay(date:Date):JSX.Element
    {
        if ( isBefore(date, startOfToday() ))
        {
            return <td key={date.toDateString()} style={{borderWidth:0}} className="primary disabled"></td>  
        }

        let bookings = Bookings.getBookingsOnDate(date)
        return <CalendarDay showNames={this.props.showNames} key={date.toDateString()} date={date} bookings={bookings} showFire={this.props.showFire} showFirstAid={this.props.showFirstAid}   />
    }

}