import React from "react";
import { Badge, Button, Card, Col, Container, Form, Nav, Row, Tab, Table, Tabs } from "react-bootstrap";
import { GiHealthCapsule, GiSkeletonKey } from "react-icons/gi";
import { App } from "../App";
import { StringHelpers } from "../logic/StringHelper";
import { observer } from "mobx-react";
import {BiPlusMedical} from  "react-icons/bi";
import { Document } from "firestorter";
import { User } from "../models/DataModels";
import { MdRemoveCircle } from "react-icons/md";

@observer
export class AdminTeam extends React.Component<{},{}>
{
    public addRole(doc:Document<User>,roleName:string)
    {
        let roles = doc.data.customRoles || [];

        if (!roles.includes(roleName))
        {
            roles.push(roleName);
        }

        doc.update({customRoles: roles})


    }

    render()
    {

        let roles = App.Settings.Roles.docs;

        return  <Table striped bordered hover variant="dark">
            <thead>
                <th></th>
                <th></th>
                <th>Keys</th>
                <th>Book days further in advance</th>
            </thead>

            <tbody>
    
            {App.Users.map( user => 
                <><tr>
                    <td colSpan={4} style={{ fontSize: "1.5em" }} className={user.data.allowBooking ? "booking-enabled" : "booking-disabled"}>
                        <Form.Check checked={user.data.allowBooking} inline id={"check-" + user.id} type="checkbox"
                            label={StringHelpers.displayName(user)}
                            onChange={(e) => user.update({ allowBooking: e.currentTarget.checked })}
                        />

                            <Button style={{float:"right"}} onClick={()=>  {if (window.confirm(`Delete user: ${StringHelpers.displayName(user)}`)) user.delete()}} variant="danger"><MdRemoveCircle size="24"/></Button>
                    </td>

                </tr>
                <tr>
                    <td style={{width:20}}></td>
                    <td>
                 
                    <Form.Check inline id={"fire-"+user.id} type="checkbox" label="Fire Warden"
                        checked={user.data.isFireWarden} 
                        onChange={(e) => user.update({ isFireWarden: e.currentTarget.checked })}
                    />
                    <Form.Check inline id={"first-"+user.id} type="checkbox" label="First Aider" 
                        checked={user.data.isFirstAider} 
                        onChange={(e) => user.update({ isFirstAider: e.currentTarget.checked })}
                    />

                    {roles.map( role =>  <Form.Check inline id={`role-${user.id}-${role.id}`} type="checkbox" label={role.data.name} 
                        checked={ user.hasRole(role.id as string) } 
                        onChange={(e) => user.enableRole(role.id as any, e.currentTarget.checked)}
                    />)}

                    
                    </td><td>
                        
                    <Form.Check inline id={"lock-"+user.id} type="checkbox" label="Lock"
                        checked={user.data.canLock} 
                        onChange={(e) => user.update({ canLock: e.currentTarget.checked })} />
                    <Form.Check inline id={"unlock-"+user.id} type="checkbox" label="Unlock" 
                        checked={user.data.canUnlock} 
                        onChange={(e) => user.update({ canUnlock: e.currentTarget.checked })} />                    

                    </td>

                    <td>
                        <BiPlusMedical/>
                    <Form.Control style={{width:80,display:"inline", marginLeft:5}} value={user.data.canBookAdditionalDaysAhead} type="number" placeholder="days" required 
                        onChange={(e) => user.update({ canBookAdditionalDaysAhead: parseInt(e.currentTarget.value) })} />   

                        
                    </td>
                </tr></>
             )}
    
            </tbody>
        </Table>
    
    }
}