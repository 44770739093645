
import { observer } from "mobx-react";
import React from "react";
import { Badge, Button, Card, Col, Container, Form, Row, Table } from "react-bootstrap";
import { App } from "../App";
import { Booking } from "../models/DataModels";
import { Calendar } from "../components/Calendar";
import firebase from "firebase";
import { makeObservable, observable } from "mobx";
import { MenuScreen } from "../components/Layouts";
import { Bookings } from "../logic/Bookings";

import { startOfToday, format } from "date-fns";
import { RiAliensFill } from "react-icons/ri"
import { UserBadge } from "../components/UserBadge";
import { StringHelpers } from "../logic/StringHelper";
import { Resources } from "../logic/Resources";
import {endOfToday, add } from "date-fns"
import { Clock } from "../components/Clock";

export interface IProps {

}

@observer
export class DashboardPage extends React.Component<IProps, {}>
{

    constructor(props:IProps)
    {
        super(props);
        this.scheduleRefresh();
    }

    scheduleRefresh()
    {
        let now = new Date(); 
        let endOfDay:Date = endOfToday()
 
        var msTillMidnight = endOfDay.getTime() - now.getTime() + 5000;
        setTimeout(()=>{
            this.scheduleRefresh();
            this.forceUpdate();
        }, msTillMidnight); 
    }

    render() {

        let date = new Date();
        let bookings = Bookings.getBookingsOnDate(date).sort((a, b) => a.data.name.localeCompare(b.data.name));
        let openHours = Bookings.getOpeningHours(date);

        let resources = Resources.getResourceUsageOnDay(date);

        return <Container>
            <Row>
                <Col style={{textAlign:"center"}}>
                    <h1 style={ {fontSize:80}}>{format(new Date(), "E dd MMM yyyy")}</h1>
                    <h1><div style={{ color: "#ccc" }}>OPEN {StringHelpers.formatHours(openHours)}</div></h1>
                
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="border-primary" >
                        <Card.Header>
                            <Container>
                                <Row>
                                    <Col>
                                        <h1><Badge bg="secondary">
                                            <RiAliensFill />
                                            {bookings.length}
                                        </Badge></h1>
                                    </Col>
                                </Row>
                            </Container>
                        </Card.Header>
                        <Card.Body>
                        
                   
                            {bookings.map(booking =>
                                <UserBadge key={booking.id} style={{ fontSize: 20, minWidth:200, display: "inline-block"  }} booking={booking.data} 
                                    showFire={true} showFirstAid={true}
                                />
                            )}
                        </Card.Body>
                    </Card>
                </Col>

            </Row>
            <Row>
                <Col xs={6}>

                <Card>
                    <Card.Body>

                    <Table striped style={{fontSize:"1.2em"}} hover variant="dark">
                {resources.filter(r => r.bookings.length > 0 && r.resource.showOnDashboard).map( resourceUsage => <tr>
                    <th>{resourceUsage.resource.name}</th>
                    <td style={{border:0}}>
                    {resourceUsage.bookings.map( booking => <span key={booking.name}>{booking.name} </span>  )}
                    </td>
                </tr> )}
                </Table>

                </Card.Body>
                </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div style={{fontSize:100, textAlign:"center"}}>
                        <Clock format="h:mm"/>
                        <small><Clock format="a"/></small>
                    </div>
                </Col>
            </Row>

        </Container>





    }

}