import { Button, Card, Col, Container, Form, FormControl, Nav, Navbar, Row } from "react-bootstrap";
import { App } from "./App";
import { BookingPage } from "./pages/BookingPage";

import { Menu } from "./components/Menu";
import { TestComponent } from "./TestComponent";
import { UserMenu } from "./components/UserMenu";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory
} from "react-router-dom";
import { HomePage } from "./pages/HomePage";
import {BookingState, BookingFormState} from "./state/BookingState";
import { BookingForm } from "./components/BookingForm";
import { ImportantInfo } from "./components/ImportantInfo";
import { observer } from "mobx-react";
import { GuidePage } from "./pages/GuidePage";
import { ManageBookingForm } from "./components/ManageBooking";
import { AdminPage } from "./pages/AdminPage";
import { MenuScreen } from "./components/Layouts";
import { SeatingPlanPage } from "./pages/SeatingPlan";
import { Header } from "./components/Header";
import { ResourcesPage } from "./pages/ResourcesPage";
import { DashboardPage } from "./pages/DashboardPage";
import { EmbeddedBookingPage } from "./pages/EmbeddedBookingPage";
import { TrendsPage } from "./pages/TrendsPage";
import { AdminExport } from "./components/AdminExport";


function AppComponent() {



  return  <>
<Router>

  <Switch>
    <Route path="/admin/export">
      <AdminExport/>
    </Route>

    <Route path="/book-embed">
      <EmbeddedBookingPage />
    </Route>

    <Route>
  
        <Header/>


        <Switch>
            <Route path="/book">
              <BookingPage />
            </Route>
            <Route exact path="/" component={BookingPage} />
            <Route path="/test">
              <TestComponent />
            </Route>
            <Route path="/guide">
              <GuidePage title="Guide" docName="Guide.pdf" />
            </Route>
            <Route path="/plan">
              <SeatingPlanPage />
            </Route>
            <Route path="/faq">
              <GuidePage title="FAQ" docName="FAQ.pdf" />
            </Route>
            <Route path="/admin">
              <AdminPage />
            </Route>
            <Route path="/resources">
              <ResourcesPage />
            </Route>  
            <Route path="/dashboard">
              <DashboardPage />
            </Route>       
            <Route path="/trends">
              <TrendsPage />
            </Route>           
          </Switch>

          
          </Route>
      </Switch>
      </Router>

    <div style={{position: "fixed"}}>
      {BookingState.State === BookingFormState.ImportantInfo && <ImportantInfo/>}
      {BookingState.State === BookingFormState.CreateBooking && <BookingForm date={BookingState.Date}/>}
      {BookingState.State === BookingFormState.ModifyBooking && <ManageBookingForm date={BookingState.Date}/>}
      </div>
    </>
  
}

export default observer(AppComponent);