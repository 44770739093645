import React from "react";
import { Button, Card, Dropdown, Nav } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import { useRouteMatch, Link as RouterLink, useHistory  } from "react-router-dom";
import { App } from "../App";
import { RiAliensFill } from "react-icons/ri";
import { GiBorderedShield,GiHive, GiInterceptorShip, GiAbstract077, GiAbstract010, GiAbstract065, GiHelp, GiAbstract009, GiKaleidoscopePearls, GiAbstract051, GiAbstract097, GiAlienSkull, GiUfo, GiSpinningRibbons, GiTriorb, GiChart } from "react-icons/gi";
import { observer } from "mobx-react";
import {ImMenu} from "react-icons/im"

const CustomToggle = React.forwardRef((props, ref) => (



    <a className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
      href=""
      ref={ref as any}
      onClick={(e) => {
        e.preventDefault();
        (props as any).onClick(e);
      }}
    >
      {props.children}

    </a>
  ));

  
function NavLink(props:{path:string,children:any})
{
    const navigation = useHistory();

  let active:boolean = useRouteMatch({path: props.path,exact:true}) as any;
  return  <Nav.Link className="text-white"
   active={active} href={props.path}
   onClick={(e)=> { navigation.push(props.path); e.preventDefault()}} style={{fontSize:"1.2em"}} >{props.children}</Nav.Link>

}

  
@observer
export class Menu extends React.Component
{

    render()
    {
      //<NavLink path="/"><GiInterceptorShip size="28"/> Home</NavLink>
        return <div >

        <Nav defaultActiveKey="/home" variant="pills" className="flex-column">
            
            <NavLink path="/book"><GiAbstract051 size="28"/> Book</NavLink>
            <NavLink path="/dashboard"><GiAbstract051 size="28"/> Dashboard</NavLink>
           
            {App.UserIsAdmin && <NavLink path="/resources"><GiInterceptorShip size="28"/> Resources</NavLink>}
            <NavLink path="/guide"><GiTriorb size="28"/> Guide</NavLink>
            <NavLink path="/faq"><GiAbstract077 size="28"/> FAQ</NavLink>
            <NavLink path="/plan"><GiKaleidoscopePearls size="28"/> Seating Plan</NavLink>
            <NavLink path="/trends"><GiChart  size="28"/> Trends</NavLink>

           {App.UserIsAdmin && <NavLink path="/admin/capacity"><GiAbstract065 size="28"/> Admin</NavLink>}
           
        </Nav>



        </div>

    }

}