
import { observer } from "mobx-react";
import React from "react";
import { Badge, Button, Card, Col, Container, Form, Nav, Row, Spinner, Tab, Table, Tabs } from "react-bootstrap";
import { DualScreen, MenuScreen } from "../components/Layouts";
import Chart from "react-apexcharts";
import { makeObservable, observable } from "mobx";
import { AggregateCollection, Collection, Document, ICollection, Mode } from "firestorter";
import { Booking } from "../models/DataModels";
import { startOfToday, subMonths } from "date-fns";
import { Bookings } from "../logic/Bookings";
import { ApexOptions } from "apexcharts";
import { groupBy } from "../logic/util";
import ReactApexChart from "react-apexcharts";



const options:ApexOptions = {
    chart: {
        foreColor: '#eee',
      //  type: 'rangeArea',
     // stacked: false,
      //height: 350,
    //   zoom: {
    //     type: 'x',
    //     enabled: true,
    //     autoScaleYaxis: true
    //   },
      toolbar: {
        //autoSelected: 'zoom',
        show:false
      },

    },
    

    stroke: {
        curve: 'smooth',
        width: [0,3]
      },

    dataLabels: {
      enabled: false
    },
    markers: {
        hover: {
          sizeOffset: 5
        }
      },
    // title: {
    //   text: 'Number of bookings',
    //   align: 'left'
    // },

    colors:['#ea33f4','#ea33f4'],
    legend: {
        markers: {
            fillColors: ['#ea33f466','#ea33f4',],
        }
    },
    fill: {
        opacity: [0.24, 1]
      },

    yaxis: {
      title: {
        text: 'Bookings'
      },
      labels: {
        formatter: function(val) {
          return val.toFixed(0);
        }
      }
    },
    xaxis: {
      type: 'datetime',
    },
    tooltip: {
        theme: 'dark',
        shared: true,

        y: {
            formatter: (value, x)=> x ? "" : value.toString()
        }
   
    },

    grid: {
        borderColor: "#535A6C",
        
        xaxis: {
        
        }
      }
  
  }

@observer
export class TrendsPage extends React.Component<{}, {}>
{

    private bookings: Collection<Document<Booking>>  = new Collection<Document<Booking>>("bookings")

    @observable
    from: Date = subMonths(startOfToday(), 6);   

    @observable
    to: Date = startOfToday();   

    @observable
    data: any[] = []
    
    constructor(props:{})
    {
        super(props) 
        makeObservable(this); 


  
        this.bookings.mode= Mode.Off;
        this.bookings.query = (ref) => ref
          .where('date', '>=', this.from)
          .where('date', '<=', this.to)
          .orderBy('date')
  
    }

    async componentDidMount() 
    {
      await this.bookings.fetch();

      let bookings = Bookings.bucketBookingsByWeek( this.bookings.docs );


      let avgBookings = Array.from(bookings.entries()).map( ([date,weekBookings])=> ({
        x: new Date(date).getTime(),
        y: weekBookings.length / Array.from(new Set(weekBookings.map(x=>x.data.date.toDate().toString()))).length
      }))

      let peak = Array.from(bookings.entries()).map( ([date,weekBookings])=> { 

        let byDay = groupBy(weekBookings, t => t.data.date.toDate().toDateString())
        let values = Object.values(byDay).map(x=>x.length)
        let max = Math.max(...values);
        let min = Math.min(...values);

        return {
           
            x: new Date(date).getTime(),
            y: [min,max]
        }
   
        
    })


      this.data = [
        {
            type: 'rangeArea',
            name: 'min/max per week',
            data: peak,
        },
        {
            name: 'Average bookings per day',
            type: 'line',
            data: avgBookings,
            
        }
       
    ]

    }
    

    render() {

        let series:ApexOptions["series"] = [];
        if (this.bookings.isLoaded)
        {

            series = this.data
        }

        
        return <MenuScreen title="Trends">
        <div >
      
        <h3>Bookings per day</h3>
        {this.bookings.isLoading ? <div><Spinner animation="border"/></div> :
        <ReactApexChart
              options={options}
              series={series}
              type="rangeArea"
              width="100%"
              height={400}
            />}

        </div>
    </MenuScreen>


    }

}