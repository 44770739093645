import { IWorkingHours, User } from "../models/DataModels";
import {Document} from 'firestorter'
import { UserData } from "../models/UserData";

export class StringHelpers
{
    public static toTitleCase(str:string): string
    {
        return str.replace(
        /\w[\S]*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
        );
    }

    public static emailToName(email:string)
    {
        return this.toTitleCase(email.split('@')[0].replace(".", " "))
    }

    public static displayName(user:UserData): string
    {
        if (user.hasData && user.data.displayName)
        {
            return user.data.displayName;
        }
        return StringHelpers.emailToName(user.id as string)
    }

    public static plural(value:number, text:string)
    {
        if ( value == 1 )
        {
            return `${value} ${text}`
        }
        
        return `${value} ${text}s`
    }

    public static formatHours(time:IWorkingHours|undefined)
    {
        if ( time == undefined)
        {
            return "";
        }
        let start = isNaN(time.startHour) ? "" : 
            time.startHour.toString().padStart(2, '0') + ":" + time.startMinute.toString().padStart(2, '0')

        let end = isNaN(time.endHour) ? "" : 
            time.endHour.toString().padStart(2, '0') + ":" + time.endMinute.toString().padStart(2, '0')  
            
        if (!start && !end )
        {
            return ""
        }

        return start + " - " + end
    }

}