import React from "react";
import { Card } from "react-bootstrap";

export class SidePanel extends React.Component
{
    render()
    {
        return <Card className="card border-primary mb-3" style={{borderColor:"var($tkg-blue)",minHeight:200}}>
        <Card.Body>
                {this.props.children}
        </Card.Body>
    </Card>
    }
}