import React from "react";
import { Badge, Button, Card, Col, Container, Form, Nav, Row, Tab, Table, Tabs } from "react-bootstrap";
import { App } from "../App";
import {MdAddCircle,MdRemoveCircle}  from "react-icons/md"; 

import DatePicker, { registerLocale } from "react-datepicker";
import { observer } from "mobx-react";
import { makeObservable, observable } from "mobx";
import { startOfToday } from "date-fns";

@observer
export class AdminCapacity extends React.Component<{},{}>
{
    
    constructor(props:{})
    {
        super(props) 
        makeObservable(this); 
    }
    
    
    @observable
    addCapacity: number = NaN;

    @observable
    addDate: Date|null = startOfToday();

    render()
    {
        return <>
        <p>Set the maximum number of people who can book per day.</p>  
    <Table striped bordered hover variant="dark">
  <thead>
    <tr>
      <th>Capacity</th>
      <th>Effective from date</th>
    </tr>
  </thead>
  <tbody>
    {App.Settings.Capacity.docs.slice().sort((a,b)=>a.data.date.seconds-b.data.date.seconds).map( c => <tr>
      <td>{c.data.capacity}</td>
      <td>{c.data.date.toDate().toDateString()}</td>
      <td>
        <Button onClick={()=>c.delete()} variant="danger"><MdRemoveCircle size="24"/></Button>
      </td>
    </tr>)}

    <tr><td colSpan={2}>Add new:</td></tr>
    <tr>
    <td><Form.Control value={this.addCapacity} type="number" placeholder="Enter capacity" required onChange={e=>this.addCapacity = parseInt( e.currentTarget.value)} /></td>
    <td>
        <DatePicker  locale="en-GB" dateFormat="dd MMM yyyy" className="form-control" selected={this.addDate} onChange={(date) => this.addDate = date as Date} /> 
    </td>
    <td>
        <Button disabled={!this.addDate || isNaN( this.addCapacity)|| this.addCapacity < 0 } onClick={()=>App.Settings.AddCapacity(this.addDate as Date,this.addCapacity as number)} 
        variant="secondary"><MdAddCircle size="24"/></Button>
      </td>
    </tr>


  </tbody>
</Table>
        </>
    }
}