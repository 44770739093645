import React from "react";
import { Badge, Button, Card, Col, Container, Form, Modal, Nav, Row, Tab, Table, Tabs } from "react-bootstrap";
import { App } from "../App";
import { observer } from "mobx-react";
import {SortableContainer, SortableElement,SortableHandle} from 'react-sortable-hoc';
import { Booking, ICustomRole, ImportantInfo, Resource } from "../models/DataModels";
import {Document} from 'firestorter'
import arrayMove from 'array-move';
import {MdContentCopy,  MdDragHandle, MdModeEdit, MdRemoveCircle} from "react-icons/md"
import { UploadComponent } from "./UploadComponent";
import { makeObservable, observable } from "mobx";
import { RiFolder2Fill } from "react-icons/ri";
import Select from 'react-select'
import { ResourceTable } from "./AdminResourceTable";


interface ResourceCardProps
{
  resource: Document<Resource>
}

const ResourceCard = (props:ResourceCardProps) => <Card bg={props.resource.data.enabled ? "primary" : "dark"}>
  <Card.Body>
    <Button style={{ float: "right" }} onClick={()=> props.resource.delete()}>Delete</Button>

    
    <Form.Check inline label="Enabled" checked={props.resource.data.enabled}
      onChange={e => props.resource.data.enabled = e.currentTarget.checked}
    />

    <Form.Label>Name:</Form.Label>
    <Form.Control placeholder="tst" value={props.resource.data.name} onChange={e => props.resource.data.name = e.currentTarget.value} />
    <Form.Control onChange={e => props.resource.data.description = e.currentTarget.value} as="textarea" value={props.resource.data.description} />

    <Form.Control onChange={e => props.resource.data.quantity = parseInt( e.currentTarget.value) }  type="number" value={props.resource.data.quantity} />

  </Card.Body>
</Card>




@observer
export class AdminResources extends React.Component<{},{}>
{

  @observable
  editing:boolean = false

  constructor(props:{})
  {
      super(props) 
      makeObservable(this); 
  }

  editResource: Document<Resource>|null = null;

  @observable
  editData:Resource = {} as any

    edit(resource:Document<Resource>)
    {
      this.editData = {...resource.data};
      this.editResource = resource;
      this.editing = true;
    }

    render()
    {

      
      let rolesMap = new Map<string, ICustomRole>( App.Settings.Roles.docs.map(object => {
        return [object.id as string, object.data];
      }))

      let options = App.Settings.Roles.docs.map(role =>  ({"value": role.id as string, "label": role.data.name as string }));
      

      // bind data
      this.resources.map((value, index) => {
        let a:any = value.data.description
        a = value.data.name
        a = value.data.enabled
        a = value.data.quantity
      });

      


      return <><h1>Resource List</h1><Table striped bordered hover variant="dark">
        <thead>
          <tr>
            <td width={10}></td>
            <td>Name</td>
            <td>Description</td>
            <td>Quantity</td>
            <td>Required Roles</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          

          {this.resources.map(resource=><tr key={resource.id}>

            <td><Form.Check inline checked={resource.data.enabled} disabled={true}
              onChange={e => resource.data.enabled = e.currentTarget.checked}
            /></td>

            <td>
              {resource.data.group}{resource.data.group && <br/>}
              <strong>{resource.data.name}</strong>
            </td>
            <td><strong>{resource.data.description}</strong></td>
            <td><strong>{resource.data.quantity}</strong></td>
            <td><strong>
              {resource.data.requiredRoleIds?.map(roleId=><Badge key={roleId} pill bg="primary">{rolesMap.get(roleId)?.name}</Badge>)}
            
            </strong></td>

            <td> 

            <Button  onClick={()=>this.edit(resource)}  variant="primary"><MdModeEdit size="24"/></Button>
              <Button  onClick={()=>resource.delete()} variant="danger"><MdRemoveCircle size="24"/></Button>

              <Button  onClick={()=> App.Database.Resources.add({...resource.data,name: resource.data.name + " (Copy)"}) } variant="info"><MdContentCopy size="24"/></Button>

            </td>

            



          </tr>)}


        </tbody>
      </Table>
      
      <Form>

     
        
        <Modal
        show={this.editing}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        contentClassName="bg-dark"
        onHide={()=>{this.editing =false; }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Resource
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

     




<br/>

    <Form.Label>Name:</Form.Label>
    <Form.Check inline checked={this.editData.enabled}
      onChange={e => this.editData.enabled = e.currentTarget.checked} label="Enabled" style={{float:"right"}}
    />

    <Form.Check inline checked={this.editData.showOnDashboard}
      onChange={e => this.editData.showOnDashboard = e.currentTarget.checked} label="Show on Dashboard" style={{float:"right"}}
    />

    <Form.Control placeholder="Resource name" value={this.editData.name} onChange={e => this.editData.name = e.currentTarget.value} />

    <Form.Label>Description:</Form.Label>
    <Form.Control onChange={e => this.editData.description = e.currentTarget.value} as="textarea" value={this.editData.description} />

    <Form.Label>Quantity:</Form.Label>
    <Form.Control onChange={e => this.editData.quantity = parseInt( e.currentTarget.value) }  type="number" value={this.editData.quantity} />

    <Form.Label>Group:</Form.Label>
    <Form.Control placeholder="Name of a Group of similar resources" value={this.editData.group} onChange={e => this.editData.group = e.currentTarget.value} />

  <Form.Label >Required Roles:</Form.Label>
  <Select isMulti={true} 
    options={options} onChange={selectedRoles=>this.editData.requiredRoleIds = selectedRoles.map(role=>role.value)} 
    value={this.editData.requiredRoleIds?.map(roleId=>({label: rolesMap.get(roleId)?.name, value: roleId}))}
    />




    <hr/>

        <Button onClick={e => {this.editResource?.update(this.editData); this.editing = false}}>Save</Button>

        </Modal.Body>
        
        </Modal>
       

                        

  
                
                
                <div style={{ marginTop: 10 }}>
                  <Button onClick={e => this.addNew()}>Add New</Button>{" "}
                 
                </div>
              </Form>
      
            </>
    
    }

    get resources()
    {
      return App.Resources;
    }


    async addNew()
    {
      let doc = await App.Database.Resources.add({
        enabled: true, name: "", description: "", quantity: 1, requiredRoleIds: [], group: "", showOnDashboard: false
      } as Resource) 

      this.edit(doc);

    }

}