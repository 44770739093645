import { observer } from "mobx-react";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { App } from "../App";
import {BookingState} from "../state/BookingState";

@observer
export class ImportantInfo extends React.Component<{},{}>
{
    render()
    {

        return <Modal
        show={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName="bg-dark"
        onHide={()=>BookingState.CloseForm()}
      >
        <Modal.Header closeButton >
          <Modal.Title id="contained-modal-title-vcenter">
            Important Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p>Please ensure you have read the following information before booking:</p>
          <div style={{margin:"5px 30px"}}>
              
              {App.Settings.ImportantInfo.docs.filter(info=>info.data.enabled).slice().sort((a,b)=> a.data.order - b.data.order ).map(info=><>
                <strong>{info.data.title}</strong>
                <p>{info.data.details}</p>
              </>)}
           
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>BookingState.CloseForm()} variant="dark" >Cancel</Button>
          <Button onClick={()=>BookingState.ConfirmImportantInfo()} variant="primary" >I confirm that I have read this information</Button>
        </Modal.Footer>
      </Modal>
    }
}