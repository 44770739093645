import { format, isSameDay } from "date-fns";
import { observer } from "mobx-react";
import React from "react";
import { Alert, Badge, Button, ButtonGroup, Col, Form, Modal, Row, Spinner, ToggleButton } from "react-bootstrap";
import {BookingState, BookingFormState} from "../state/BookingState";

import { ICollection, Document } from "firestorter";
import { Booking, IWorkingHours } from "../models/DataModels";
import { Bookings } from "../logic/Bookings";
import { App } from "../App";
import { TimeSelect } from "./TimeSelect";
import { rawListeners } from "process";
import { Resources } from "../logic/Resources";
import { makeObservable, observable } from "mobx";
import { domainToASCII } from "url";
import {GoTriangleRight} from "react-icons/go"
import { ResourceBooking } from "./ResourceBooking";
import { TimeNote } from "./TimeNote";

export interface IProps
{
    date:Date;
}

@observer
export class BookingForm extends React.Component<IProps,{}>
{

  @observable
  selectedResources:Set<string>;

  @observable 
  rememberResources: boolean = false;

  @observable 
  bookingNote: string = "";

  constructor(props:IProps)
  {
      super(props) 
      let availableToBook = App.UserPrefs.chosenResources.filter( x => Resources.getFullyBookedResources(this.props.date).indexOf(x) < 0 )
      this.selectedResources =  new Set<string>(availableToBook);
      makeObservable(this); 
  }


    private get workingHoursValid(): boolean
    {
      if (!App.UserData.isKeyHolder)
      {
        return true;
      }
      
      if(!App.UserPrefs.workingHoursAreSet || !App.UserPrefs.workingHours)
      {
        return false;
      }

      if (App.UserPrefs.workingHours.startHour >=  App.UserPrefs.workingHours?.endHour)
      {
        return false;
      }

      return true;

    }

    confirm()
    {
      BookingState.ConfirmBooking({
        date:this.props.date,
        workingHours: App.UserPrefs.workingHours,
        resourceIds: App.Settings.enableResourceBooking ? Array.from(this.selectedResources) : [],
        note: this.bookingNote
      })

      if ( App.UserPrefs.workingHoursAreSet)
      {
        App.UserPrefs.update({workingHours: App.UserPrefs.workingHours })
      }

      if ( this.rememberResources )
      {
        App.UserPrefs.chosenResources = Array.from( this.selectedResources);
      }
    }

    toggleResource(resourceId: string)
    {
      if (this.selectedResources.has(resourceId))
      {
        this.selectedResources.delete(resourceId); 
      }
      else
      {
        this.selectedResources.add(resourceId);
      }
    }


    render()
    {
      let bookings = Bookings.getBookingsOnDate(this.props.date);
     
     
        return <Modal
        show={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName="bg-dark"
        onHide={()=>BookingState.CloseForm()}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirm Booking
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2>{format(this.props.date,"E dd MMM yyy")}</h2>

{/*
          {bookings.length > 0 && <strong>Who else is in? </strong>}
          {bookings?.map(b=><><Badge pill bg="secondary">{b.data.name}</Badge>&nbsp;</>)}
          {bookings.length == 0 && "No-one else has booked this date yet."}
*/}          

          
          <p style={{marginTop:5}}><strong>When are you in?</strong> <TimeNote value={this.bookingNote} onChange={e=>{this.bookingNote =e}} /> </p>

          { App.UserData.isKeyHolder && <div>
          <Alert style={{marginTop:5}} variant="primary">
            <h5>You are a key-holder</h5>
          <p>Please enter the time that you will be in the office so that others can plan accordingly.</p>
       
          <TimeSelect />   </Alert>
          </div>
          }
          
      

            
          {App.Settings.enableResourceBooking && <>
          <h5 style={{marginTop:15}}>Book resources</h5>
  

            <ResourceBooking date={this.props.date} 
              onResourceSelect={id => this.toggleResource(id)} 
              selectedResources={this.selectedResources} />

            <small><Form.Check type="checkbox" label="book these every time?" id="rememberResource" 
              checked={this.rememberResources} onChange={e=>this.rememberResources=e.currentTarget.checked} /></small>
        </>}

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>BookingState.CloseForm()} variant="dark" disabled={BookingState.State == BookingFormState.BookingInProgress} >Cancel</Button>
          <Button onClick={()=>this.confirm()} variant="secondary" 
            disabled={BookingState.State == BookingFormState.BookingInProgress || !this.workingHoursValid}
          >
          
              {BookingState.State == BookingFormState.BookingInProgress && <Spinner as="span" animation="border"  size="sm" role="status" />}
              {BookingState.State == BookingFormState.BookingInProgress ? "Booking..." : "Confirm Booking" }
          </Button>
 
        </Modal.Footer>
      </Modal>
    }
}