import firebase from "firebase";
import {  makeObservable, observable } from "mobx";
import { App } from "../App";
import { Bookings } from "../logic/Bookings";
import { Booking, IWorkingHours } from "../models/DataModels";

export enum BookingFormState
{
    None,
    ImportantInfo,
    ModifyBooking,
    CreateBooking,
    BookingInProgress,
    CancelInProgress
}

class BookingState
{
    constructor() {
        makeObservable(this)
    }
    
    @observable
    public State:BookingFormState = BookingFormState.None;

    @observable
    public HoverDate:Date|undefined = undefined;

    @observable
    public Date:Date = new Date();

    private hasShownImportantInformation = false;

    public CloseForm()
    {
        this.State = BookingFormState.None;
    }

    public async CancelBooking(bookingInfo:{date:Date})
    {
        this.State = BookingFormState.CancelInProgress;

        let booking = await Bookings.getCurrentUserBookingOnDate(bookingInfo.date);
        await booking?.delete();

        this.CloseForm();

    }

    public async ConfirmBooking(bookingInfo:{date:Date, resourceIds: string[], workingHours: IWorkingHours|null, note?: string })
    {
        this.State = BookingFormState.BookingInProgress;

        let booking:Booking = {
            date: firebase.firestore.Timestamp.fromDate( bookingInfo.date),
            name: App.User?.displayName || "<unknown>",
            email: App.User?.email || "<unknown>",
            created: firebase.firestore.Timestamp.now(),
            bookedResources: bookingInfo.resourceIds,
            note: bookingInfo.note || ""
        }

        if (bookingInfo.workingHours != null)
        {
            booking.workingHours = {...bookingInfo.workingHours}
        }

        await App.Database.Bookings.add(booking)


        this.CloseForm();

    }

    public ConfirmImportantInfo()
    {
        this.hasShownImportantInformation = true;
        this.State = BookingFormState.CreateBooking;
    }

    public OpenBookingForm(date:Date)
    {
        this.Date = date;

        if ( Bookings.isCurrentUserBookedOnDate(date) )
        {
            this.State = BookingFormState.ModifyBooking;
            return;
        }

        if ( !Bookings.currentUserCanBookDate(date) )
        {
            return;
        }

        if (!this.hasShownImportantInformation)
        {
            this.State = BookingFormState.ImportantInfo;
        }
        else
        {
            this.State = BookingFormState.CreateBooking;
        }

    }

}

let bookingState = new BookingState();
export {bookingState as BookingState};