import { App } from "../App";
import { AggregateCollection, Document, ICollection } from "firestorter";
import { Booking, IWorkingHours } from "../models/DataModels";
import { Collection } from "../Database";
import { differenceInHours, isAfter, isBefore, isSameDay, startOfDay, startOfWeek } from "date-fns";
import { computed, toJS } from "mobx";
import { MdYoutubeSearchedFor } from "react-icons/md";
import { DateHelper } from "./DateHelper";

export class Bookings
{
    public static bucketBookingsByDay(bookings:Document<Booking>[]): Map<string,Document<Booking>[]>
    {
        let result = new Map<string,Document<Booking>[]>();
        for (let booking of bookings )
        {
            let date = booking.data.date.toDate().toDateString();
            if ( !result.has(date))
            {
                result.set(date,[]);
            }

            result.get(date)?.push(booking);
        }
        return result;
    }

    public static bucketBookingsByWeek(bookings:Document<Booking>[]): Map<string,Document<Booking>[]>
    {
        let result = new Map<string,Document<Booking>[]>();
        for (let booking of bookings )
        {
            let date = booking.data.date.toDate()//.toDateString();
            let week = startOfWeek(date).toDateString()
            if ( !result.has(week))
            {
                result.set(week,[]);
            }

            result.get(week)?.push(booking);
        }
        return result;
    }

    public static getBookingsOnDate(date:Date): Document<Booking>[] //Promise<Document<Booking>[]>
    {
        return App.Database.BookingsByDay.get(date.toDateString()) || []
    }

    public static getNumberOfBookingsOnDate(date:Date): number
    {
        return this.getBookingsOnDate(date).length
    }

    public static getOpeningHours(date:Date): IWorkingHours|undefined
    {
        let bookings = Bookings.getBookingsOnDate(date);

        let workingHours:IWorkingHours =
        {
            startHour: NaN,
            startMinute: NaN,
            endHour: NaN,
            endMinute: NaN
        }


        for ( let booking of bookings)
        {
            let user = App.Database.getUserDoc(booking.data.email )
            let bookingHours = booking.data.workingHours;

            if ( bookingHours == undefined || (!user.data.canUnlock && !user.data.canLock))
            {
                continue;
            }

            if ( user.data.canLock )
            {
                if ( isNaN(workingHours.endHour) || DateHelper.WorkingHoursAreBefore(workingHours,bookingHours, "end"))
                {
                    workingHours.endHour = bookingHours.endHour
                    workingHours.endMinute = bookingHours.endMinute
                }
                
            }
            if ( user.data.canUnlock )
            {
                if ( isNaN(workingHours.startHour) || DateHelper.WorkingHoursAreBefore(bookingHours,workingHours, "start"))
                {
                    workingHours.startHour = bookingHours.startHour
                    workingHours.startMinute = bookingHours.startMinute
                }
            }

        }

        return workingHours;
    
        

    }

    public static isCurrentUserBookedOnDate(date:Date)
    {
        let bookings = this.getBookingsOnDate(date);
        return bookings.some( b => b.data.email === App.User?.email && isSameDay(b.data.date.toDate(),date)   )
    }

    public static isUserBookedOnDate(date:Date, email:string)
    {
        let bookings = this.getBookingsOnDate(date);
        return bookings.some( b => b.data.email === email && isSameDay(b.data.date.toDate(),date)   )
    }

    public static getCurrentUserBookingOnDate(date:Date) : Document<Booking> | undefined
    {
        let bookings = this.getBookingsOnDate(date);
        return bookings.find( b => b.data.email === App.User?.email && isSameDay(b.data.date.toDate(),date)   )
    }

    public static getSpacesOnDay(date:Date)
    {
        return Bookings.getCapacityOnDay(date)- this.getBookingsOnDate(date).length
    }

    public static getCapacityOnDay(date:Date)
    {
        let capacities = App.Settings.Capacity.docs
            .slice()
            .sort((a,b)=>b.data.date.seconds-a.data.date.seconds)
            .map( c => c.data )

        for ( let capacity of capacities )
        {
            if ( differenceInHours(date,capacity.date.toDate()) >= 0 )
            {
                return capacity.capacity;
            }
        }

        return 0;


    }

    public static isDayFullyBooked(date:Date): boolean
    {
        let capacity = this.getCapacityOnDay(date);
        let utilised = this.getBookingsOnDate(date).length;

        return ( utilised >= capacity);
    }

    public static daysUserCanBookInAdvance(): number
    {
        let days = App.Settings.maxDaysInAdvance;
        if ( App.UserData.hasData)
        {
            days = days  + (App.UserData.data.canBookAdditionalDaysAhead || 0)
        }
        return days;
    }

    public static currentUserCanBookDate(date:Date): boolean
    {
        if ( !App.UserData.data.allowBooking)
        {
            return false;
        }
        if ( this.isDayFullyBooked(date))
        {
            return false;
        }

        // Check if a keyholder has booked
        if ( !App.UserData.isKeyHolder && App.Settings.keyHolderMustBookFirst && !this.isKeyHolderBookedOnDate(date))
        {
            return false;
        }

        return true;
    }

    public static isKeyHolderBookedOnDate(date:Date)
    {
        return this.isKeyHolderBooked( this.getBookingsOnDate(date) );
    }

    public static isKeyHolderBooked(bookings:Document<Booking>[])
    {
        let unlock = false
        let lock = false

        for ( let booking of bookings)
        {
            let user = App.Database.getUserDoc(booking.data.email )
            unlock = unlock || user.data.canUnlock
            lock = lock || user.data.canLock

            if ( lock && unlock)
            {
                return true;
            }
        }

        return false;
    }


}