import React from "react";
import { Button, Card, Col, Container, Form, FormControl, Nav, Navbar, Row } from "react-bootstrap";
import { Menu } from "./Menu";
import { SidePanel } from "./SidePanel";



export class DualScreen extends React.Component<{ sidebar: JSX.Element, title: string }, {}>
{
    render() 
    {
        return <Container style={{ maxWidth: 1500, marginTop: 20, fontWeight: 200 }}>
            <Row><Col ><h1>{this.props.title}</h1></Col></Row>
            <Row>
                <Col className="sidebar">
                    {this.props.sidebar}
                </Col>
                <Col>


                    <Card className="border-primary" >
                        <Card.Body>
                            {this.props.children}
                        </Card.Body>
                    </Card>


                </Col>
            </Row>
        </Container>
    }
}

export class MenuScreen extends React.Component<{ title: string }, {}>
{
    render()
    {
        return <DualScreen title={this.props.title} sidebar={<SidePanel><Menu/></SidePanel>} >{this.props.children}</DualScreen>
    }
}