import React from "react";
import { Badge } from "react-bootstrap";
import { App } from "../App";
import { Booking, User } from "../models/DataModels";
import {  Document } from 'firestorter';
import { observer } from "mobx-react";
import {IoKeyOutline, IoKey, IoFlame, IoMedkitOutline, IoMedkit} from "react-icons/io5"
import { StringHelpers } from "../logic/StringHelper";

export interface IProps
{
    booking:Booking

    style?: React.CSSProperties

    showFire?: boolean
    showFirstAid?: boolean
}

@observer
export class UserBadge extends React.Component<IProps,{}>
{


    render()
    {
        let booking = this.props.booking;
        let userDoc = App.Database.getUserDoc(booking.email)
        let userData = userDoc.data;


        let userName = StringHelpers.displayName(userDoc);

        let icons = [ ]
        const iconSize = 18;

       
        if (userDoc.hasData)
        {
                if (userData.canLock)
                {
                    icons.push(<IoKey key="lock" color="#ea39b8" size={iconSize}/>)
                }
                else if (userData.canUnlock)
                {
                    icons.push(<IoKeyOutline key="unlock" size={iconSize}/>)
                }

                if (this.props.showFire && userData.isFireWarden)
                {
                    icons.push(<IoFlame key="fire" color="#ff8f00" size={iconSize}/>)
                }

                if (this.props.showFirstAid && userData.isFirstAider)
                {
                    icons.push(<IoMedkit key="firstAid" color="#2debff" size={iconSize}/>)
                }
        }

        let isCurrentUser = booking.email == App.User?.email;

        let style: React.CSSProperties = {...{border: isCurrentUser ? "1px solid" : "", "whiteSpace":"normal"}, ...this.props.style}
 
        return <>
        <Badge pill style={style} className="border-secondary" bg={"dark"}>{icons} {userName} {booking.visitor && " (visitor)"} {booking.note && `(${booking.note})`}</Badge>
        </>
    }
} 