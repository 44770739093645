import { addDays } from "date-fns";
import { IWorkingHours } from "../models/DataModels";

export class DateHelper
{
    public static MondayBefore(date:Date)
    {
        return addDays(date, 1-date.getDay());
    }

    public static WorkingHoursAreBefore(a:IWorkingHours, b:IWorkingHours, type:"start"|"end")
    {

        if ( type == "start")
        {
            let aa = (60 * a.startHour) + a.startMinute;
            let bb = (60 * b.startHour) + b.startMinute;
            return aa < bb
        }
        else
        {
            let aa = (60 * a.endHour) + a.endMinute;
            let bb = (60 * b.endHour) + b.endMinute;
            return aa < bb
        }

    }
}