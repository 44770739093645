import {  makeObservable, observable } from "mobx";
import firebase from "firebase";
import { firebaseConfig } from "./firebase-config";
import { Database, PublicSettings, UserPrefs } from "./Database";
import { Booking, IUserPrefs, Resource, User } from "./models/DataModels";
import { Document } from "firestorter";

import * as mobx from "mobx"
import { UserData } from "./models/UserData";



class App
{
    constructor() {
        makeObservable(this)
    }
    
    @observable
    public UserSignedIn = false;

    @observable
    public UserIsAdmin = false;

    public User: firebase.User|null = null

    public Database: Database = new Database();

    public get Bookings(): Document<Booking>[] 
    {
        return this.Database.Bookings.docs;
    }

    public get Resources(): Document<Resource>[] 
    {
        return this.Database.Resources.docs;
    }

    public get Settings(): PublicSettings
    {
        return this.Database.PublicSettings;
    }

    private userData: UserData|null = null

    public get UserData(): UserData
    {
        return this.userData as any;
    }
    
    private userPrefs?: UserPrefs = undefined;
  
    public get UserPrefs(): UserPrefs
    {
        return this.userPrefs as any;
    }

    public get Users(): UserData[]
    {
        return this.Database.Users.docs
    }
    

    public async SignOut()
    {
        return new Promise<void>((resolve,reject)=>{
            firebase.auth().signOut().then(function() {
                resolve();
              }, function(error) {
                reject('Sign Out Error: '+ error);
              });
        });

    }

    public async initialise()
    {
        firebase.initializeApp(firebaseConfig);
        this.Database?.initialise();

        mobx.configure({
            enforceActions: "never",
        })

        firebase.auth().onAuthStateChanged(async user => {
   
            this.User = user;

            if ( user )
            {

                await this.Database.PublicSettings.fetch();

                this.userData = new UserData("users/" + user?.email);
                await this.userData.fetch();

                if ( !this.userData.hasData)
                {
                    this.userData.create(user);
                }                

                this.userPrefs = new UserPrefs("prefs/" + user?.email);
                await this.userPrefs.fetch();

                if ( !this.userPrefs.hasData)
                {
                    this.userPrefs.loadDefaults();
                }
                            
                let token = await user.getIdTokenResult()
                this.UserIsAdmin = ("admin" in token.claims && token.claims["admin"] == true)
            }

            // Notify sign in
            this.UserSignedIn = (!!user);

         });  
         

    }

}

let appState = new App();
(global as any)["App"] = appState

export { appState as App }