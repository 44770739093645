import React from "react";
import { Badge, Button, Card, Col, Container, Form, Nav, Row, Tab, Table, Tabs } from "react-bootstrap";
import { App } from "../App";
import {MdAddCircle,MdRemoveCircle}  from "react-icons/md"; 

import DatePicker, { registerLocale } from "react-datepicker";
import { observer } from "mobx-react";
import { makeObservable, observable } from "mobx";
import { startOfToday } from "date-fns";

@observer
export class AdminRoles extends React.Component<{},{}>
{
    
    constructor(props:{})
    {
        super(props) 
        makeObservable(this); 
    }
    
    
    @observable
    nameOfNewRoleToAdd: string = "";



    render()
    {
        return <>
    <Table striped bordered hover variant="dark">
  <thead>
    <tr>
      <th>Role Name</th> 
    </tr>
  </thead>
  <tbody>
    {App.Settings.Roles.docs.slice().sort((a,b)=>a.data.name.localeCompare(b.data.name)).map( c => <tr key={c.id}>
      <td>{c.data.name}</td>
      <td>
        <Button onClick={()=>c.delete()} variant="danger"><MdRemoveCircle size="24"/></Button>
      </td>
    </tr>)}

    <tr><td colSpan={2}>Add new:</td></tr>
    <tr>
    <td><Form.Control value={this.nameOfNewRoleToAdd} placeholder="Enter name" required onChange={e=>this.nameOfNewRoleToAdd =  e.currentTarget.value} /></td>

    <td>
        <Button disabled={ this.nameOfNewRoleToAdd.length === 0  } onClick={(e)=>{App.Settings.AddCustomRole(this.nameOfNewRoleToAdd); this.nameOfNewRoleToAdd = ""  }} 
        variant="secondary"><MdAddCircle size="24"/></Button>
      </td>
    </tr>


  </tbody>
</Table>
        </>
    }
}