import { observer } from "mobx-react";
import React from "react";
import { Button } from "react-bootstrap";
import { App } from "./App";
import { Booking } from "./models/DataModels";

@observer
export class TestComponent extends React.Component<{},{}>
{



    async componentDidMount()
    {
        //await App.Database.Bookings.fetch();
    }

    render()
    {
        
console.log()

        return <div>HELLO {App.Database.Bookings.isLoaded ? "T" : "F"}

            <p>Welcome, {App.UserSignedIn ? "T" :"F"}. {App.User?.displayName}</p>

            {App.Bookings.map((e) => <div><pre>!!{e.id} - {e.data.name} - {e.data.date.toDate().toDateString()} </pre></div> )}

            <span className="badge rounded-pill bg-primary">Primary</span>

            <div className="callout">...</div>
 
            <Button onClick={()=>App.SignOut()} variant="outline-info">Logout</Button>
        </div>;
    }

}