import firebase from "firebase";
import { Booking, DefaultUserPrefs, ICapacityRule, IPublicSettings, IUserPrefs, IWorkingHours, User, ImportantInfo, Resource, ICustomRole} from "./models/DataModels";
import { Collection as FireStorterCollection, Document, ICollectionOptions, initFirestorter, Mode } from 'firestorter';
import { basename } from "path";
import { addMonths, startOfToday, subMonths } from "date-fns";
import { autorun, computed, makeObservable, observable, reaction } from "mobx";
import { Bookings } from "./logic/Bookings";
import { UserData } from "./models/UserData";



export class Collection<T extends object> extends FireStorterCollection<Document<T>>
{

    constructor(collectionName: string, options: ICollectionOptions<T>|undefined = undefined)
    {
        super(collectionName, options as any)
    }



}

export class PublicSettings extends Document<IPublicSettings> implements IPublicSettings {
    
    // wrapped settings for convenience of use
    public get maxDaysInAdvance(): number  {  return this.data.maxDaysInAdvance;}
    public get keyHolderMustBookFirst():boolean { return this.data.keyHolderMustBookFirst }
    public get showSpaceCount(): boolean { return this.data.showSpaceCount }
    public get enableResourceBooking(): boolean { return this.data.enableResourceBooking }


    private capacity: Collection<ICapacityRule>|null = null

    private importantInfo: Collection<ImportantInfo>|null = null

    private customRoles: Collection<ICustomRole>|null = null

    // Create an on-demand messages subcollection
    get Capacity(): Collection<ICapacityRule> 
    {
      if (!this.capacity) 
      {
        this.capacity = new Collection<ICapacityRule>("settings/public/capacity");
      };
      
      return this.capacity;
    }

    get Roles(): Collection<ICustomRole> 
    {
      if (!this.customRoles) 
      {
        this.customRoles = new Collection<ICustomRole>("settings/public/roles");
      };
      
      return this.customRoles;
    }

    get ImportantInfo(): Collection<ImportantInfo> 
    {
      if (!this.importantInfo) 
      {
        this.importantInfo = new Collection<ImportantInfo>("settings/public/importantInfo");
      };
      
      return this.importantInfo; 
    }

    public AddCapacity(date:Date, capacity: number)
    {
        this.Capacity.add(
        {
            date: firebase.firestore.Timestamp.fromDate(date),
            capacity: capacity
        } as ICapacityRule
        )


        
    }

    public AddCustomRole(name:string)
    {
        this.Roles.add(
        {
            name: name
        } as ICustomRole
        )
    }

  }

export class UserPrefs extends Document<IUserPrefs> 
{
    get showBookings() 
    {
        return this.data.showBookings;
    }

    set showBookings(v:boolean)
    {
        this.update({showBookings:v})
    }

    get showBadges() 
    {
        return this.data.showBadges || false;
    }

    set showBadges(v:boolean)
    {
        this.update({showBadges:v})
    }

    public get workingHours(): IWorkingHours|null
    {
        if (this.data.workingHours == null)
        {
            this.data.workingHours = {} as any
        }
        return this.data.workingHours
    }

    public get workingHoursAreSet(): boolean
    {
        let hours = this.workingHours;
        return hours != undefined 
            && !isNaN(hours.endHour) 
            && !isNaN(hours.endMinute)
            && !isNaN(hours.startHour)
            && !isNaN(hours.startMinute)
    }

    public set workingHours(v: IWorkingHours|null )
    {
        this.update({workingHours: v})
    }

    public get chosenResources(): string[]
    {
        if (this.data.chosenResources == null)
        {
            this.data.chosenResources = []
        }
        return this.data.chosenResources
    }

    public set chosenResources(v: string[] )
    {
        this.update({chosenResources: v})
    }

    public loadDefaults()
    {
        this.set(DefaultUserPrefs)
    }
}


export class Database
{
    private bookings: Collection<Booking>|null = null

    public get Bookings(): Collection<Booking>
    {
        return this.bookings as Collection<Booking>;
    }

    private resources: Collection<Resource>|null = null

    public get Resources(): Collection<Resource>
    {
        return this.resources as Collection<Resource>;
    }

    private users: FireStorterCollection<UserData>|null = null

    public get Users(): FireStorterCollection<UserData>
    {
        return this.users as FireStorterCollection<UserData>;
    }

    private publicSettings: PublicSettings|null = null

    public get PublicSettings(): PublicSettings
    {
        return this.publicSettings as PublicSettings;
    }


    private userMap: Map<string,Document<User>> = new Map<string,Document<User>> ();

    getUserDoc(userEmail:string): UserData
    {
        if (!this.userMap.has(userEmail))
        {
            this.userMap.set(userEmail, new Document<User>(`users/${userEmail}`));
        }
        return this.userMap.get(userEmail) as any;
    }


    constructor() {
        makeObservable(this);
     
    }

    @computed     
    get BookingsByDay() 
    {
        return Bookings.bucketBookingsByDay(this.Bookings.docs);
    }


    async initialise()
    {
        if (global["location"].hostname === "localhost") {
            //firebase.firestore().useEmulator("localhost", 8080);
          }

          
        initFirestorter({ firebase: firebase });
        this.bookings = new Collection<Booking>("bookings");
        this.bookings.query = (ref) => ref.where('date', '>', subMonths(startOfToday(), 1)).orderBy('date')


        this.publicSettings = new PublicSettings("settings/public")

        this.users = new FireStorterCollection<UserData>("users",{createDocument:(source, options) => new UserData(source, options)}); 


        this.resources = new Collection<Resource>("resources");
        this.resources.query = (ref) => ref.orderBy("name")

       
        //this.users.query = ref => ref.orderBy(firebase.firestore.FieldPath.documentId())

        // Example date filter.
        // 

    }
}


