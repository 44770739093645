import {  User } from "./DataModels";
import { Document } from "firestorter";
import firebase from "firebase";
import { ThermometerSun } from "react-bootstrap-icons";

export class UserData extends Document<User>
{
    public get isKeyHolder()
    {
        return this.data.canLock || this.data.canUnlock;
    }

    public create(user:firebase.User)
    {
        let data:User = {
            allowBooking: false,
            canLock: false,
            canUnlock: false,
            isFireWarden: false,
            isFirstAider: false,
            displayName: user.displayName || null,
            canBookAdditionalDaysAhead: 0
        }
        this.set(data);
    }

    public hasRole(roleName:string)
    {
        return this.data.customRoles?.includes(roleName);
    }
    
    public addRole(roleName:string)
    {
        let roles = this.data.customRoles || [];

        if (!roles.includes(roleName))
        {
            roles.push(roleName);
        }

        this.update({customRoles: roles})


    }

    public removeRole(roleName:string)
    {
        console.log(this.data.customRoles)
        if (!this.data.customRoles)
        {
            this.data.customRoles = [];
        }


        this.update({customRoles: this.data.customRoles.filter(x=>x !== roleName)})
    }

    public enableRole(roleName:string, enabled:boolean)
    {
        if (enabled)
        {
            this.addRole(roleName)
        }
        else
        {
            this.removeRole(roleName);
        }
    }
}