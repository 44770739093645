
import React from "react";
import { Badge, Button, Card, Col, Container, Form, Modal, Nav, Row, Tab, Table, Tabs } from "react-bootstrap";
import { App } from "../App";
import { observer } from "mobx-react";
import {SortableContainer, SortableElement,SortableHandle} from 'react-sortable-hoc';
import { Booking, ICustomRole, ImportantInfo, Resource } from "../models/DataModels";
import {Document} from 'firestorter'
import arrayMove from 'array-move';
import {MdContentCopy,  MdDragHandle, MdModeEdit, MdRemoveCircle} from "react-icons/md"
import { UploadComponent } from "./UploadComponent";
import { makeObservable, observable } from "mobx";
import { RiFolder2Fill } from "react-icons/ri";
import Select from 'react-select'
import { Bookings } from "../logic/Bookings";
import { addDays, format, startOfToday } from "date-fns";

interface IProps
{
  daysToShowAhead:number
}

@observer
export class ResourceTable  extends React.Component<IProps,{}>
{

  renderHeader() 
  {
    let currentDay = startOfToday();
    let dayElements:JSX.Element[] = [];

    dayElements.push(<th style={{background:"#170229"}} className="sticky-col first-col"></th>)

    

    for(let i = 0; i < this.props.daysToShowAhead; i++)
    {
      let isWeekend = currentDay.getDay() == 6 ||  currentDay.getDay() == 0
      dayElements.push(<th >{!isWeekend && format(currentDay,"E dd MMM")}</th>)
      currentDay = addDays(currentDay,1)
    }

    return dayElements;
  }


  renderRow(resourceId:string, bookings:  Map<string,Document<Booking>[]>)
  {
    let currentDay = startOfToday();

    let dayElements:JSX.Element[] = [];

    for(let i = 0; i < this.props.daysToShowAhead; i++)
    {
      let dayBookings = bookings.get(currentDay.toDateString());

      let names = []
      if (dayBookings) 
      {
        for (let dayBooking of dayBookings) 
        {
          if (dayBooking.data.bookedResources !== undefined) 
          {
            for (let resource of dayBooking.data.bookedResources) 
            {
              if (resource == resourceId) 
              {
                names.push(dayBooking.data.name);
              }
            }
          }
        }
      }

      let isWeekend = currentDay.getDay() == 6 ||  currentDay.getDay() == 0

        dayElements.push(
            <td style={isWeekend ? {minWidth:50} : {}} className={isWeekend ? "booking-disabled" : ""}>{names.map(name=> <div><Badge key={name} bg="primary">{name}</Badge></div>)}</td>
        )
        currentDay = addDays(currentDay,1)
    }

    return dayElements;
  }

  render()
  {

    let bookings = Bookings.bucketBookingsByDay( App.Bookings.filter(b=>b.data.date.toDate() >= startOfToday()) );


    return <div className="view" >
  <div className="scrolling-table" >

    <Table striped bordered hover variant="dark" className="resource-table">
      <thead>
        {this.renderHeader()}
      </thead>
    <tbody>
    {App.Resources.map(resource=>
    <tr key={resource.id}>
      <td className="sticky-col first-col">{resource.data.name}</td>
      {this.renderRow(resource.id as string, bookings)}



    </tr>)}

    </tbody>
    </Table>
    </div>
    </div>
  }
}
