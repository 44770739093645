import React from "react";
import { Alert, Badge, Button, Card, Col, Container, Form, Modal, Nav, Row, Spinner, Tab, Table, Tabs } from "react-bootstrap";
import { GiHealthCapsule, GiSkeletonKey } from "react-icons/gi";
import { App } from "../App";
import { StringHelpers } from "../logic/StringHelper";
import { observer } from "mobx-react";
import { Bookings } from "../logic/Bookings";
import { AggregateCollection, Collection, Document, ICollection, Mode } from "firestorter";
import { Booking } from "../models/DataModels";
import { makeObservable, observable } from "mobx";
import { format, startOfToday, subMonths } from "date-fns";
import {IoAddCircle} from "react-icons/io5"
import DatePicker from "react-datepicker";
import firebase from "firebase";

@observer
export class AdminExport extends React.Component<{},{}>
{
  constructor(props:{})
  {
      super(props) 
      makeObservable(this); 

      this.bookings.mode= Mode.Off;
      this.bookings.query = (ref) => ref
        .where('date', '>=', this.from)
        .where('date', '<=', this.to)
        .orderBy('date')

  }
    
  @observable
  from: Date = subMonths(startOfToday(), 12);   

  @observable
  to: Date = startOfToday();   

  private bookings: Collection<Document<Booking>>  = new Collection<Document<Booking>>("bookings")

  

  async componentDidMount() 
  {
    await this.bookings.fetch();
  }
  

    render()
    {

      let data: JSX.Element[] = [];

      this.bookings.docs.forEach( (booking: Document<Booking>) => 
      {
     

        data.push(<tr>
            <td style={{width:120}}>{format(booking.data.date.toDate(),"yyyy-MM-dd")}</td>
            <td>{booking.data.email}</td>
            <td> {booking.data.name}</td>
               
                <td>{booking.data.note}</td>
          
        </tr>)
      })

      return <div> 

        

           {this.bookings.isLoading && <div><Spinner animation="border"/></div>}

          <table style={{background: "white",color:"black"}}>


            <tbody>{data}</tbody>
        </table>

      </div>
    
    }
}