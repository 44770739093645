import { makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { App } from "../App";
import { DefaultUserPrefs, IWorkingHours } from "../models/DataModels";

@observer
export class TimeSelect extends React.Component<{value?: IWorkingHours},{}>
{

    render()
    {
        let workingHours:IWorkingHours = this.props.value || App.UserPrefs.workingHours || ({} as any)

        return<Row>
        <Col>
        
        <Form.Select  style={{display:"inline",width:80}} size="lg"
            value={workingHours.startHour}
            onChange={e=> workingHours.startHour = parseInt(e.currentTarget.value)}
        >
          {["--",7,8,9,10,11,12,13,14].map(t=><option key={t}>{t}</option>)}
        </Form.Select> 

        <div style={{color:"#30115e",display:"inline-block",margin:6,fontFamily: "impact"}} >:</div>

        <Form.Select  style={{display:"inline",width:100,marginRight:10}}  size="lg"
            value={workingHours.startMinute}
            onChange={e=> workingHours.startMinute = parseInt(e.currentTarget.value) }
        > 
          {["--","00","15","30","45"].map(t=><option key={t} value={parseInt(t)}>{t}</option>)}
        </Form.Select>
  
        to
        
        <Form.Select style={{display:"inline",width:80,marginLeft:10}} size="lg"
            value={workingHours.endHour}
             onChange={e=> workingHours.endHour = parseInt(e.currentTarget.value) }
        >
          {["--",12,13,14,15,16,17,18,19].map(t=><option key={t}>{t}</option>)}
        </Form.Select>

         <div style={{color:"#30115e",display:"inline-block",margin:6,fontFamily: "impact"}} >:</div>

        <Form.Select style={{display:"inline",width:100}}  size="lg"
            value={workingHours.endMinute}
             onChange={e=> workingHours.endMinute = parseInt(e.currentTarget.value) } 
        > 
          {["--","00","15","30","45"].map(t=><option key={t} value={parseInt(t)}>{t}</option>)}
        </Form.Select>
  
        </Col>
        </Row>
    }
}