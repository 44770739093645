import React from "react";
import { Alert, Badge, Button, Card, Col, Container, Form, Modal, Nav, Row, Spinner, Tab, Table, Tabs } from "react-bootstrap";
import { GiHealthCapsule, GiSkeletonKey } from "react-icons/gi";
import { App } from "../App";
import { StringHelpers } from "../logic/StringHelper";
import { observer } from "mobx-react";
import { Bookings } from "../logic/Bookings";
import { AggregateCollection, Collection, Document, ICollection, Mode } from "firestorter";
import { Booking } from "../models/DataModels";
import { makeObservable, observable } from "mobx";
import { format, startOfToday, subMonths } from "date-fns";
import {IoAddCircle} from "react-icons/io5"
import DatePicker from "react-datepicker";
import firebase from "firebase";

@observer
export class AdminHistory extends React.Component<{},{}>
{
  constructor(props:{})
  {
      super(props) 
      makeObservable(this); 

      this.bookings.mode= Mode.Off;
      this.bookings.query = (ref) => ref
        .where('date', '>=', this.from)
        .where('date', '<=', this.to)
        .orderBy('date')

  }
    
  @observable
  from: Date = subMonths(startOfToday(), 1);   

  @observable
  to: Date = startOfToday();   

  private bookings: Collection<Document<Booking>>  = new Collection<Document<Booking>>("bookings")

  

  async componentDidMount() 
  {
    await this.bookings.fetch();
  }
  

    render()
    {
      let bookings = Bookings.bucketBookingsByDay( this.bookings.docs );

      let data: JSX.Element[] = [];

      bookings.forEach( (bookings: Document<Booking>[], key: string) => 
      {
        let date = new Date(key)

        data.push(<tr>
            <td style={{width:120}}>{format(date,"E dd MMM")}</td>
            <td>
                {bookings?.sort((a,b)=>a.data.name.localeCompare(b.data.name) ).map(b=><span>{b.data.name}, </span>)}
          </td>
        </tr>)
      })

      return <div> 

           <h1>Booking History</h1>
           <div style={{marginBottom:10}}>
           <DatePicker  locale="en-GB" dateFormat="dd MMM yyyy" className="form-control" selected={this.from} onChange={(date) => {this.from = date as Date;this.bookings.fetch();}} /> 
           {" to "}
           <DatePicker  locale="en-GB" dateFormat="dd MMM yyyy" className="form-control" selected={this.to} onChange={(date) => {this.to = date as Date;this.bookings.fetch();}} /> 
          </div>

           {this.bookings.isLoading && <div><Spinner animation="border"/></div>}

          <Table striped bordered hover variant="dark">
            <tbody>{data}</tbody>
        </Table>

      </div>
    
    }
}