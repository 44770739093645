import React from "react";
import { Container, Form, Navbar } from "react-bootstrap";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import logo from '../logo.svg'
import { UserMenu } from "./UserMenu";

class Header extends React.Component<RouteComponentProps<{}>,{}>
{
    

    render()
    {
        return       <Navbar className="justify-content-between" bg="dark" variant="dark" style={{backgroundColor:"#1a1a1a"}}>
        <Container fluid>
          <Navbar.Brand 
          >
            <a href="/" onClick={(e)=> { this.props.history.push("/"); e.preventDefault()}}>
            <img
            src={logo}
            height="50"
            className="d-inline-block align-top"
          /></a>
            <span style={{marginLeft:10,fontSize:34, marginTop:5, fontWeight:200, display:"inline-block" }} className="h1">STUDIO</span>
          </Navbar.Brand>

          <Form>
            <UserMenu/>

          </Form>
        </Container> 
      </Navbar>
    }
}

const t = withRouter(Header);
export {t as Header};