import React from "react";
import { Dropdown } from "react-bootstrap";
import { App } from "../App";

const CustomToggle = React.forwardRef((props, ref) => (
  <a className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
    href=""
    ref={ref as any}
    onClick={(e) => {
      e.preventDefault();
      (props as any).onClick(e);
    }}
  >
    {props.children}

  </a>
));



export class UserMenu extends React.Component 
{

  render() 
  {
    return <Dropdown>
      <Dropdown.Toggle as={CustomToggle}  >
        <img src={App.User?.photoURL as any} alt="" width="32" height="32" className="rounded-circle me-2" />
        <strong>{App.User?.displayName}</strong>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={()=> App.SignOut()} >Log Out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>

  }

}