
import { observer } from "mobx-react";
import React from "react";
import { Alert, Badge, Button, Card, Col, Container, Form, Row, Table } from "react-bootstrap";
import { App } from "../App";
import { Booking } from "../models/DataModels";
import { Calendar } from "../components/Calendar";
import { addDays, format, startOfToday } from "date-fns";
import { Bookings } from "../logic/Bookings";
import { DualScreen, MenuScreen } from "../components/Layouts";
import { BookingState } from "../state/BookingState";
import { DateInfoPopup } from "../components/DateInfoPopup";
import { makeObservable, observable } from "mobx";
import { Menu } from "../components/Menu";
import { SidePanel } from "../components/SidePanel";



@observer
export class BookingPage extends React.Component<{}, {}>
{
 

    render() {

        const keyProps = { display: "flex", verticalAlign: "middle", height: 32, padding: 10, alignItems: "center",
        marginRight:10, width:50 };

        const keyLabelProps = {
            marginRight:15,
            verticalAlign:"middle",
            lineHeight: 2
        }

        let startDate = startOfToday();
        let endDate = addDays(startDate, Bookings.daysUserCanBookInAdvance())

        //<DateInfoPopup date={BookingState.HoverDate}/>
        return <MenuScreen title="Book" > <div>

        
        <div style={{background:"#380863",textAlign:"center",padding:"5px"}}>
            <h5 style={{color:"#bc87e7"}}>Guide for <b style={{color: "#EA33F4" }}>recommended</b> bookings per month</h5>
            <table className="guidanceTable">
                <tbody>
                    <tr style={{fontWeight: "bold", fontSize: "10pt", textAlign: "center"}}>
                        <td>&lt; 10 MILES</td>
                        <td>30 MILES</td>
                        <td style={{border:"none"}}>50 MILES</td>
                    </tr>
                    <tr style={{fontWeight: "bold", fontSize: "20pt", color: "#EA33F4"}}>
                        <td  >10 days</td>
                        <td >5 days</td>
                        <td style={{border:"none"}} >1 day</td>
                    </tr>
                </tbody>
            </table>
          
        </div>
            


            {App.UserData.data.allowBooking ?  
                <p style={{fontSize: "1.2em"}}>Click on a date to reserve your space or to modify an existing booking.</p> : 
                <Alert variant="danger">Your account is not yet activated for booking, please contact the studio team for more information</Alert> 
            }
           
            <div style={{ display: "flex", marginBottom: 0}}>
            <Form.Check id="chk-show-bookings" label="Show who's booked" checked={ App.UserPrefs.showBookings} 
            onChange={e=> App.UserPrefs.showBookings = e.currentTarget.checked}/>
            <div style={{width:10}}/>
            <Form.Check id="chk-show-badge" label="Show Badges" checked={ App.UserPrefs.showBadges} 
            onChange={e=> App.UserPrefs.showBadges = e.currentTarget.checked}/>
            </div>

            <Calendar showNames={App.UserPrefs.showBookings} startDate={startDate} endDate={endDate}
                bookings={App.Bookings} showFire={App.UserPrefs.showBadges} showFirstAid={App.UserPrefs.showBadges} />


<div style={{ display: "flex", marginBottom: 10 }}>
                
                <div className="no-keyholder" style={keyProps}></div><strong style={keyLabelProps}>NO KEYHOLDER</strong>
{/*
                <div className="day-full" style={keyProps}></div><strong style={keyLabelProps}>FULLY BOOKED</strong>
                <div className="bg-info" style={keyProps}></div><strong style={keyLabelProps}>TODAY</strong>
                <div className="bg-dark" style={keyProps}></div><strong style={keyLabelProps}>AVAILABLE</strong>
*/}
            </div>


        </div></MenuScreen>; 
    }

}