
import { observer } from "mobx-react";
import React from "react";
import { Badge, Button, Card, Col, Container, Form, Row, Table } from "react-bootstrap";
import { App } from "../App";
import { Booking } from "../models/DataModels";
import { Calendar } from "../components/Calendar";
import { startOfToday } from "date-fns";
import firebase from "firebase";
import { makeObservable, observable } from "mobx";
import { MenuScreen } from "../components/Layouts";

export interface IProps
{
  docName:string
  title:string
}

@observer
export class GuidePage extends React.Component<IProps,{}>
{

    @observable
    private url?: string = undefined;

    constructor(props:IProps) {
      super(props)
      makeObservable(this) 

    }

    async componentDidMount()
    {
      await this.loadUrl();
    }

    async componentDidUpdate(prevProps:IProps) {
      // Typical usage (don't forget to compare props):
      if (this.props.docName !== prevProps.docName) {
        await this.loadUrl();
      }
    
    }

    async loadUrl()
    {
      var storage = firebase.storage();
      var pathReference = storage.ref(this.props.docName);
      this.url = undefined;
      this.url = await pathReference.getDownloadURL();
    }
    

    render()
    {
      
        return <MenuScreen title={this.props.title}>

      <div >
          {!this.url && <div>Loading...</div>}

          <iframe style={{ width: "100%", height: "calc(100vh - 220px)" }}
            src={ this.url}
            
          ></iframe>
          </div>

        </MenuScreen>;
    }

}