import React from "react";
import ReactDOM from "react-dom";

//import "bootstrap/dist/css/bootstrap.min.css";
import './App.scss';
//import "./theme_1624641890320.css"

import "./index.css"
import {AuthWrapper} from "./auth";
import AppComponent from "./AppComponent";
import { App } from "./App";

App.initialise();

ReactDOM.render(

    <div className="App">
    <AuthWrapper>
      <AppComponent/>
      </AuthWrapper>
      </div>
  ,
  document.getElementById("root")
);

