import { format } from "date-fns";
import React from "react";
import { Badge } from "react-bootstrap";
import { Bookings } from "../logic/Bookings";
import { StringHelpers } from "../logic/StringHelper";
import { UserBadge } from "./UserBadge";

import { createAtom, autorun, observable } from "mobx"
import { observer } from "mobx-react";

class ClockModel {
    atom
    intervalHandler: NodeJS.Timeout|null = null
    currentDateTime:Date|undefined

    constructor() {
        this.atom = createAtom(
            "Clock",
            () => this.startTicking(),
            () => this.stopTicking()
        )
    }

    getTime() {
        if (this.atom.reportObserved()) {
            return this.currentDateTime
        } else {
            return new Date()
        }
    }

    tick() {
        this.currentDateTime = new Date()
        this.atom.reportChanged() 
    }

    startTicking() {
        this.tick() // Initial tick.
        this.intervalHandler = setInterval(() => this.tick(), 1000)
    }

    stopTicking() {
        clearInterval(this.intervalHandler as NodeJS.Timeout)
        this.intervalHandler = null
    }
}

@observer
export class Clock extends React.Component<{format:string},{}>
{
    @observable
    clock = new ClockModel();
    

    render()
    {
        return <span>{format(this.clock.getTime() as Date,this.props.format)}</span>
    }
}