interface GroupedObject {
    [key: string]: any[];
}

/**
 * Groups objects in an array based on a custom selector function that extracts a grouping key from each object.
 *
 * @template T - The type of objects in the input array.
 * @param {T[]} arr - An array of objects that you want to group.
 * @param {(item: T) => string} selector - A function that takes an item of type T and returns a string representing the grouping key for that item.
 * @returns {GroupedObject} - An object where keys are strings (grouping keys) and values are arrays of objects of type T.
 */
export function groupBy<T>(arr: T[], selector: (item: T) => string): GroupedObject {
    return arr.reduce((acc: GroupedObject, obj: T) => {
        const key = selector(obj);
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
    }, {});
}